<script>
export default {
    name: "InputSwitch",
    props: {
        name: { type: String, default: null },
        value: { type: Boolean, default: true },
        checkbox: { type: Boolean, default: false },
        form: { type: String, default: null },
        formValue: { type: [String, Number, Boolean], default: null },
    },
    emits: ['input'],
    data() {
        return {
            toggle: this.value,
            toggleColors: { checked: '#47D1B0', unchecked: '#D9D9D9' },
        };
    },
    created() {
        this.toggle = this.value;
    },
    watch:{
        value(val) {
            this.toggle = val;
        }
    },
    computed: {
        fieldValue(){
            if(this.formValue !== null){
                return this.toggled ? this.formValue : null;
            }
            return this.toggled ? 1 : 0;
        },
        toggled: {
            get: function () {
                return this.toggle;
            },
            // setter
            set: function (newValue) {
                this.toggle = newValue
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<template>
<span class="d-flex align-items-center">
    <toggle-button
        v-model="toggled"
        class="mb-0"
        :color="toggleColors"
        :sync="true"
        :height="24"
        :width="44"
    />
    <input v-if="name && (!checkbox || (checkbox && fieldValue !== null ))" type="hidden" :form="form" :name="name" :value="fieldValue"/>
</span>
</template>

<style scoped lang="scss">

</style>
