export default {
  data() {
    return {
      datalist: [],
    };
  },
  methods: {
    resetFilters() {
      const lcoTableHeaders = this.$refs;
      const lcoTableHeadersKeys = Object.keys(
        lcoTableHeaders,
      );
      for (const tableHeader of lcoTableHeadersKeys) {
        lcoTableHeaders[tableHeader].children[0].dataset.direction = 'asc';
        lcoTableHeaders[tableHeader].children[1].classList.remove(
          'sortSvg--asc',
        );
        lcoTableHeaders[tableHeader].children[1].classList.remove(
          'sortSvg--desc',
        );
      }
    },

    sortTable(e) {
      const sortedList = this.dataList;
      const sortKey = e.target.dataset.sort;
      const sortDir = e.target.dataset.direction;
      const selectedHeader = this.$refs[`header-${sortKey}`];
      const selectedSvg = selectedHeader.children[1];
      this.resetFilters();
      selectedSvg.classList.add(`sortSvg--${sortDir}`);

      if (sortDir == 'asc') {
        e.target.dataset.direction = 'desc';
        sortedList.sort((a, b) => {
          if (sortKey == 'name') {
            if (a[sortKey] < b[sortKey]) {
              return -1;
            }
            if (a[sortKey] > b[sortKey]) {
              return 1;
            }
            return 0;
          }
          return parseFloat(a[sortKey]) - parseFloat(b[sortKey]);
        });
      } else {
        e.target.dataset.direction = 'asc';
        sortedList.sort((a, b) => {
          if (sortKey == 'name') {
            if (a[sortKey] > b[sortKey]) {
              return -1;
            }
            if (a[sortKey] < b[sortKey]) {
              return 1;
            }
            return 0;
          }
          return parseFloat(b[sortKey]) - parseFloat(a[sortKey]);
        });
      }
    },
  },
};
