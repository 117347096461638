import Form from '../app/Form';

const OLD_ATTR = 'fallback-src';

/**
 * Remove the uploaded state from the targets.
 *
 * @param  {Array} targets
 *
 * @return {Void}
 */
const remove = (targets) => {
  for (let target of targets) {
    target = document.querySelector(`#${target}`);

    if (!target) {
      continue;
    }

    if (target.tagName !== 'IMG') {
      target.classList.remove('uploaded');

      continue;
    }

    if (!target.getAttribute(OLD_ATTR)) {
      continue;
    }

    target.setAttribute('src', target.getAttribute(OLD_ATTR));
  }
};

/**
 * Add the uploaded state to the targets.
 *
 * @param  {Element} el
 * @param  {Array}   targets
 *
 * @return {Void}
 */
const add = (el, targets) => {
  const reader = new FileReader();
  reader.onload = (e) => {
    for (let target of targets) {
      target = document.querySelector(`#${target}`);
      if (!target) {
        continue;
      }

      if (target.tagName !== 'IMG') {
        target.classList.add('uploaded');

        continue;
      }

      if (!target.getAttribute(OLD_ATTR)) {
        target.setAttribute(OLD_ATTR, target.getAttribute('src'));
      }

      target.setAttribute('src', e.target.result);
    }
  };

  reader.readAsDataURL(el.files[0]);
};

export default {
  /**
     * Attach the event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  bind(el, binding, node) {
    let targets = binding.value || [];

    if (!Array.isArray(targets)) {
      targets = [targets];
    }

    binding.handler = _.debounce((e) => {
      if (!el.files || !el.files[0]) {
        remove(targets);

        return;
      }

      add(el, targets);
    }, 250);

    el.addEventListener('change', binding.handler, false);
  },

  /**
     * Remove the un-needed event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  unbind(el, binding, node) {
    el.removeEventListener('change', binding.handler, false);
  },
};
