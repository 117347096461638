<template>
  <span class="citbSvg">
    <svg
      width="53px"
      height="35px"
      viewBox="0 0 53 35"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 56.2 (81672) - https://sketch.com -->
      <title>Citb Card</title>
      <desc>Created with Sketch.</desc>
      <g
        id="1.0---Pre---Enrolment---Desktop---Invalid-Card-Journey"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="1.1---CSCS-Card-Number-Entry"
          transform="translate(-186.000000, -184.000000)"
          fill="#50D7B9"
        >
          <g
            id="Page-1"
            transform="translate(186.000000, 184.000000)"
          >
            <path
              id="Fill-1"
              d="M28.6739358,28.4002627 L28.7368191,28.3977385 L44.2980856,28.3977385 C44.5638185,28.3977385 44.823466,28.2980306 45.0087353,28.1251196 C45.2048232,27.9452668 45.3130096,27.7060943 45.3130096,27.4511451 C45.3130096,27.1961959 45.2048232,26.9570234 45.0107637,26.7796949 C44.823466,26.6048906 44.5638185,26.5045517 44.2886193,26.5045517 L28.7787414,26.507076 C28.7435808,26.5026585 28.7084202,26.5007653 28.6732596,26.5007653 L28.5785965,26.5058138 C28.0207602,26.5537746 27.6089756,27.0176053 27.6603641,27.5394938 C27.7083718,28.0222564 28.1532886,28.4002627 28.6739358,28.4002627"
            />
            <path
              id="Fill-3"
              d="M7.43781746,17.3542121 C7.43781746,16.4694628 8.18024687,15.7765564 9.12823052,15.7765564 L18.5945436,15.7765564 C19.5425273,15.7765564 20.2849567,16.4694628 20.2849567,17.3542121 L20.2849567,26.1890837 C20.2849567,27.073833 19.5425273,27.7667393 18.5945436,27.7667393 L9.12823052,27.7667393 C8.18024687,27.7667393 7.43781746,27.073833 7.43781746,26.1890837 L7.43781746,17.3542121 Z M9.12823052,29.6599261 L18.5945436,29.6599261 C20.6453528,29.6599261 22.3134524,28.1024645 22.3134524,26.1890837 L22.3134524,17.3542121 C22.3134524,15.4402003 20.6453528,13.8833697 18.5945436,13.8833697 L9.12823052,13.8833697 C7.07742139,13.8833697 5.40932179,15.4402003 5.40932179,17.3542121 L5.40932179,26.1890837 C5.40932179,28.1024645 7.07742139,29.6599261 9.12823052,29.6599261 L9.12823052,29.6599261 Z"
            />
            <path
              id="Fill-5"
              d="M50.7123918,8.83487161 L2.02849567,8.83487161 L2.02849567,4.10190467 C2.02849567,2.86376052 3.06843778,1.89318677 4.39507395,1.89318677 L48.3458135,1.89318677 C49.6724496,1.89318677 50.7123918,2.86376052 50.7123918,4.10190467 L50.7123918,8.83487161 Z M50.7123918,30.6065195 C50.7123918,31.8452947 49.6724496,32.8152374 48.3458135,32.8152374 L4.39507395,32.8152374 C3.06843778,32.8152374 2.02849567,31.8452947 2.02849567,30.6065195 L2.02849567,10.7280584 L50.7123918,10.7280584 L50.7123918,30.6065195 Z M48.3458135,0 L4.39507395,0 C1.97169779,0 0,1.84017754 0,4.10190467 L0,30.6065195 C0,32.8682466 1.97169779,34.7084242 4.39507395,34.7084242 L48.3458135,34.7084242 C50.7691896,34.7084242 52.7408874,32.8682466 52.7408874,30.6065195 L52.7408874,4.10190467 C52.7408874,1.84017754 50.7691896,0 48.3458135,0 L48.3458135,0 Z"
            />
            <path
              id="Fill-7"
              d="M28.6739358,22.7210179 L28.7368191,22.7184937 L44.2980856,22.7184937 C44.5638185,22.7184937 44.823466,22.6181548 45.0087353,22.4452437 C45.2048232,22.2660221 45.3130096,22.0262184 45.3130096,21.7719003 C45.3130096,21.5169512 45.2048232,21.2771475 45.0107637,21.099819 C44.823466,20.9250148 44.5638185,20.8253069 44.2886193,20.8253069 L28.7787414,20.8278312 C28.7435808,20.8234137 28.7084202,20.8215206 28.6732596,20.8215206 L28.5785965,20.826569 C28.0207602,20.8745298 27.6089756,21.3383605 27.6603641,21.859618 C27.7083718,22.3423806 28.1532886,22.7210179 28.6739358,22.7210179"
            />
            <path
              id="Fill-9"
              d="M28.6739358,17.0414576 L28.7368191,17.0389334 L44.2980856,17.0389334 C44.5638185,17.0389334 44.823466,16.9385945 45.0087353,16.7656834 C45.2048232,16.5864617 45.3130096,16.3466581 45.3130096,16.09234 C45.3130096,15.8373908 45.2048232,15.5975872 45.0107637,15.4202587 C44.823466,15.2454544 44.5638185,15.1457466 44.2886193,15.1457466 L28.7787414,15.1482709 C28.7435808,15.1438534 28.7084202,15.1419602 28.6732596,15.1419602 L28.5785965,15.1470087 C28.3088066,15.170358 28.0640348,15.2908909 27.8902603,15.4865202 C27.717162,15.6821495 27.635346,15.9282638 27.6603641,16.1800576 C27.7083718,16.6628203 28.1532886,17.0414576 28.6739358,17.0414576"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'CitbSvg',
};
</script>

<style>
  .citbSvg svg {
    vertical-align: text-bottom;
  }
</style>
