import { render, staticRenderFns } from "./InputWysiwyg.vue?vue&type=template&id=1fd68898&scoped=true"
import script from "./InputWysiwyg.vue?vue&type=script&lang=js"
export * from "./InputWysiwyg.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fd68898",
  null
  
)

export default component.exports