<template>
  <div class="reporting__wrapper">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>Reporting</h2>
        </div>
      </div>
    </div>
    <div class="reporting__tabs">
      <div
        v-if="this.$root.$data.showEnrolmentTab"
        :class="{'reporting__single-tab--active': activeTab == 'enrolments'}"
        class="reporting__single-tab"
        name="enrolments_tab"
        id="enrolments_tab"
        @click="activeTab = 'enrolments'">Enrolment</div>
      <div
        :class="{'reporting__single-tab--active': activeTab == 'verifications'}"
        class="reporting__single-tab"
        name="verifications_tab"
        id="verifications_tab"
        @click="activeTab = 'verifications'">Verifications</div>
      <div
        v-if="this.$root.$data.showCardTab"
        :class="{'reporting__single-tab--active': activeTab == 'cards'}"
        class="reporting__single-tab"
        name="lco_cards_tab"
        id="lco_cards_tab"
        @click="activeTab = 'cards'">Cards</div>
      <div v-if="clientConfigOffice === 'LCO'"
        :class="{'reporting__single-tab--active': activeTab == 'lco_cards'}"
        class="reporting__single-tab"
        name="lco_cards_tab"
        id="lco_cards_tab"
        @click="activeTab = 'lco_cards'">LCO Cards</div>
        <div v-else
             :class="{'reporting__single-tab--active': activeTab == 'lco_cards'}"
             class="reporting__single-tab"
             @click="activeTab = 'lco_cards'">Region</div>
      <div
        v-if="clientConfigOffice === 'Region'"
        :class="{'reporting__single-tab--active': activeTab == 'community_impact'}"
        class="reporting__single-tab"
        @click="activeTab = 'community_impact'">Community Impact SV</div>
    </div>
    <div class="reporting__body">
      <csv-export-tab
        v-if="activeTab === 'enrolments' && this.$root.$data.showEnrolmentTab"
        :resource="routes.enrolments"
        :reports="enrolmentsReports"
        report-type="enrolments" />
      <csv-export-tab
        v-else-if="activeTab === 'verifications'"
        :resource="routes.verifications"
        :reports="verificationsReports"
        report-type="verifications" />
      <csv-export-tab
        v-else-if="activeTab === 'cards'"
        :resource="routes.cards"
        :reports="cardReports"
        report-type="cards" />
      <csv-export-tab
        v-else-if="activeTab === 'community_impact'"
        :resource="routes.communityImpact"
        :reports="communityImpactReports"
        report-type="community impact" />
      <lco-cards-list
        v-else-if="activeTab === 'lco_cards'"
        :resource="routes.lcoCards"
      />
    </div>
  </div>
</template>

<script>
import CsvExportTab from './CsvExportTab.vue';
import LcoCardsList from '../admin/LcoCardsList.vue';

export default {
  name: 'ReportingTabs',
  components: {
    CsvExportTab, LcoCardsList,
  },
  props: {
    routes: {
      type: Object,
      default() {
        return {
          verifications: '/api/v1/admin/reporting/verifications',
          enrolments: '/api/v1/admin/reporting/enrolments',
          cards: '/api/v1/admin/reporting/cards',
          communityImpact: '/api/v1/admin/reporting/community-impact',
        };
      },
    },
  },
  data() {
    return {
      activeTab: this.$root.$data.showEnrolmentTab ? 'enrolments' : 'verifications',
      enrolmentsReports: [],
      verificationsReports: [],
      cardReports: [],
      communityImpactReports: [],
    };
  },
  computed: {
      clientConfigOffice() {
          return window.clientConfig['client-office'];
      },
  },
  mounted() {
    this.enrolmentsReports = window.userReports.filter(report => report.type === 'Enrolment');
    this.verificationsReports = window.userReports.filter(report => report.type === 'Verification');
    this.cardReports = window.userReports.filter(report => report.type === 'Card');
    this.communityImpactReports = window.userReports.filter(report => report.type === 'Community Impact');
  },
};
</script>

<style lang="scss">
.reporting {
  &__single-tab {
    padding: 1rem 1.5rem;
    z-index: 9;
    transform: translateY(1px);
    border: 1px solid #F5F4F4;
    border-bottom: 1px solid gray;
    cursor: pointer;

    &:hover {
      background: gray;
      color: #F9F9F9;
      border: 1px solid gray;
    }

    &--active,
    &--active:hover {
      color: inherit;
      background: #F9F9F9;
      border: 1px solid gray;
      border-bottom: 1px solid #F9F9F9;
    }

  }

  &__tabs {
    border-bottom: 1px solid gray;
    display: flex;
    padding-left: 3rem;
    @media (max-width: 700px) {
      padding-left: 0.25rem;
      overflow-x: scroll;
      overflow-y: hidden;
    }
    padding-top: 2rem;
  }

  &__body {
    background: #F9F9F9;
    padding: 2rem;
  }

  &__wrapper {
    padding-top: 2rem;
    background: #F5F4F4;
  }
}

.dashboard-main {
  //padding: 0;
}
</style>
