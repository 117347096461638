<template>
  <div
    ref="dropdownContainer"
    :class="{ active: focused, collapsed: !focused }"
    class="gosmart-card-selector"

  >
    <div
      v-show="goSmartCard == null || focused"
      class="gosmart-card-selector__dropdown-trigger empty"
      @click.prevent="selectPlaceholder()"
    >
      <input
        ref="searchQuery"
        v-model="goSmartSearch"
        class="gosmart-card-selector__input"
        type="text"
        placeholder="Start typing your card scheme name"
        @input="filterGoSmartCards"
      >
    </div>

    <ul
      ref="dropdownItem"
      class="unlisted-redorector__container">
      <li
        v-for="item in goSmartCards"
        :key="item.id"
        :class="{ selected: goSmartCard && goSmartCard.id === item.id }"
        class="gosmart-card-selector__item col-sm-6 col-md-4 col-lg-3"
        @click.prevent="select(item)"
      >
        <div class="gosmart-card__item-wrapper">
          <img
            :src="item.icon_src"
            class="gosmart-card__icon">
          <p
            class="gosmart-card__description"
            v-text="item.abbreviation" />
        </div>
      </li>
      <li
        v-if="!goSmartCards.length"
        class="col-md-12">
        <p class="gosmart-card__description">Nothing found for search criteria</p>
      </li>
      <li
        v-if="showButtons === true"
        class="gosmart-card-selector__item hr"/>

      <li
        v-if="uPassCert && showButtons === true"
        class="gosmart-card-selector__item unlisted-redirector__item col-md-6"
        @click.prevent="uPassCert">
        <p class="button outline btn-block">
          I only have a pass certificate
        </p>
      </li>
      <li
        v-if="uTempUser && showButtons === true"
        class="gosmart-card-selector__item unlisted-redirector__item col-md-6"
        @click.prevent="uTempUser">
        <p class="button outline btn-block">
          I'm temporarily visiting a site
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    uGoSmartCards: {
      type: Array,
      default() {
        return [];
      },
    },
    uTempUser: {
      type: Function,
      default() {
        return null;
      },
    },
    uPassCert: {
      type: Function,
      default() {
        return null;
      },
    },
    showButtons: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      goSmartCard: null,
      focused: false,
      goSmartCards: this.uGoSmartCards,
      goSmartSearch: '',
    };
  },
  watch: {
    focused(value) {
      if (value) {
        this.$nextTick(() => this.$refs.searchQuery.focus());
      }
      if (this.focused === false) {
        this.$refs.dropdownItem.scrollTo(0, 0);
      }
    },
  },
  mounted() {
    window.addEventListener('keydown', this.onEscPress);
    document.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    window.removeEventListener('keydown', this.onEscPress);
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    filterGoSmartCards() {
      const newList = this.uGoSmartCards.filter(goSmartCard => goSmartCard.name
        .toLowerCase()
        .includes(this.goSmartSearch.toLowerCase()) || goSmartCard.abbreviation
        .toLowerCase()
        .includes(this.goSmartSearch.toLowerCase()));
      this.goSmartCards = newList;
    },
    select(goSmartCard) {
      this.$emit('input', goSmartCard);
    },
    selectPlaceholder() {
      if (!this.focused) {
        this.focused = true;
        return;
      }

      this.$emit('input', null);
    },
    /**
     * onEscPress
     *
     * @param {Event} evt
     *
     * Close selected dropdown when user clicks outside of dropdown
     */
    onEscPress(evt) {
      if (evt.key === 'Escape') {
        this.focused = false;
      }
    },
    /**
     * onClickOutside
     *
     * @param {Event} evt
     *
     * Close selected dropdown when user clicks outside of dropdown
     */
    onClickOutside(evt) {
      const dropdown = this.$refs.dropdownContainer;
      const hasClickedDropdownElement = dropdown.contains(evt.target);

      this.focused = hasClickedDropdownElement;
    },
  },
};
</script>
