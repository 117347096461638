<script>

import axios from "axios";
import MsiteUserRowLinkModal from "./MsiteUserRowLinkModal.vue";

export default {
    name: "MsiteUserRow",
    components: {
        MsiteUserRowLinkModal,
    },
    props: {
        userId: { type: Object, required: true },
        user: { type: Object, required: true },
        isLinked: { type: Boolean, default: false },
        enrollment: { type: Object, required: true },
        imageUrl: { type: String, default: null },
        msiteId: { type: String, default: null },
        company: { type: String, default: null }
    },
    data() {
        return {
            msiteNumber: null,
            isLoading: false,
            showNoIdModal: false,
            showLinkModal: false,
            linkData: null,
            isNowLinked: false,
            isLinked: false,
        };
    },
    computed: {
      dateOfBirth() {
        let date = new Date(this.user.date_of_birth);
        return date.toLocaleDateString();
      },
    },
    methods: {
        async setNoId(){
            this.isLoading = true;
            try{
                await axios.post(`/group-admin/msite-verification/${this.userId}/no-msite-id`);
                this.isNowLinked = true;
            } finally{
                this.isLoading = false;
            }
        },
        async setMsiteId(){
            this.isLoading = true;
            try{
                await axios.post(`/group-admin/msite-verification/${this.userId}/update`, {
                    msiteId: this.msiteNumber,
                });
                this.isNowLinked = true;
            } finally{
                this.isLoading = false;
            }
        },
    }
}
</script>

<template>
    <div v-if="!isNowLinked" class="mb-3">
        <div class="card ">
            <div class="card-body d-flex align-items-center">
              <div class="text-dark">
                <img :src=imageUrl />
              </div>
                <div class="flex-grow-1 text-dark">
                    <h4 class="font-weight-bold mb-0">
                        {{user.first_name}} {{user.last_name}}
                    </h4>
                    <div class="py-3">
                        {{user.profession}} with {{company}}
                    </div>
                    <div class="h6 font-weight-bold">
                        URN: <a :href="'/admin/urn-verification/' + enrollment.urn">{{ enrollment.urn }}</a>
                    </div>
                    <div class="h6 font-weight-bold">
                        Date Of Birth: {{ dateOfBirth }}
                    </div>

                    <div class="h6 font-weight-bold" v-if="isLinked">
                      MSite Id: <a :href="'https://willmottdixon.msitecloud.com/Employee/' + msiteId">{{ msiteId }}</a>
                    </div>
                </div>
                <div
                    v-if="!isLinked"
                    class="flex-shrink-0 text-right"
                >
                    <div class="input-group">
                        <input type="number" name="search" v-model.number="msiteNumber" class="form-control" placeholder="MSite Number">
                        <div class="input-group-append">
                            <button @click="showLinkModal = true" type="button" class="btn btn-success text-white" :disabled="isLoading || msiteNumber === null">Link</button>
                        </div>
                    </div>
                    <button @click="showNoIdModal = true" type="submit" class="btn btn-link btn-sm p-0 mt-3" :disabled="isLoading">Operative doesn't have an MSite ID</button>
                </div>
            </div>
            <div
                v-if="showNoIdModal"
                class="project-list__modal pre-lightbox"
            >
                <div class="pre-lightbox__content project-list__lightbox--content">
                    <p>
                        Are you sure you want to set <span class="font-weight-bold">{{user.first_name}} {{user.last_name}}</span> as having no Msite ID?
                    </p>
                    <div class="pre-lightbox__footer">
                        <button
                            class="button outline"
                            @click="showNoIdModal = false"
                        >
                            No, Cancel
                        </button>
                        <button
                            class="button success"
                            @click="setNoId(); showNoIdModal = false"
                        >
                            Yes, set as No ID
                        </button>
                    </div>
                </div>
            </div>
            <msite-user-row-link-modal
                v-if="showLinkModal"
                :user="user"
                :user-id="userId"
                :link-data="linkData"
                :msite-number="msiteNumber"
                @confirm="setMsiteId(); showLinkModal = false;"
                @cancel="showLinkModal = false;">

            </msite-user-row-link-modal>
        </div>
    </div>
</template>

<style scoped lang="scss">
.text-dark{
    color: #4a4a4a!important;
}
img {
  max-width: 100%;
  height: 150px;
}

div.card-body {
  gap: 2rem;
}
</style>
