<template>
  <div
    :class="fieldClass"
    class="field field--checkbox"
  >
    <label
      :class="checkboxLable"
      class="form-check-label"
    >
      <input
        :id="inputKey"
        :form="inputForm"
        :name="inputKey"
        :value="inputValue"
        :checked="checkboxChecked"
        class="form-check-input"
        type="checkbox"
        autocomplete="off"
        @click="checkboxClick"
      >

      <span>{{ inputLabel }}</span> <label for="checkbox" />
    </label>

    <slot />

    <span
      v-for="(key, error) in errors"
      :key="key"
    >{{ error }}</span>
  </div>
</template>

<script>
import { eventBus } from '../../app';

export default {
  props: {
    inputValue: {
      type: Boolean,
      default: true,
    },
    fieldClass: {
      type: String,
      default: '',
    },
    inputForm: {
      type: String,
      default: '',
    },
    inputKey: {
      type: String,
      default: '',
    },
    inputLabel: {
      type: String,
      default: '',
    },
    inputChecked: {
      type: Boolean,
      default: false,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      labelClass: '',
      checkedInput: this.inputChecked,
    };
  },
  computed: {
    checkboxLable() {
      if (this.checkedInput) {
        return 'checkbox-checked';
      }
      return '';
    },
    checkboxChecked() {
      if (this.checkedInput) {
        return 'checked';
      }
      return '';
    },
  },
  created() {
    eventBus.$on('checkbox-clicked', (data) => {
      if (
        data.id === this.inputKey
                && data.checked === true
                && data.form === this.inputForm
      ) {
        this.checkedInput = true;
      } else {
        this.checkedInput = false;
      }
    });
  },
  methods: {
    checkboxClick(e) {
      eventBus.$emit('checkbox-clicked', {
        form: this.inputForm,
        id: this.inputKey,
        checked: e.target.checked,
      });
    },
  },
};
</script>
