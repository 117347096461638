<template>
  <div class="profile-cards">
    <!-- Green -->
    <div
      :class="{
        'cards__card--clickable': (cards.green.length >= 1),
        'cards__card--active': (card == 'green')
      }"
      class="cards__card"
      @click="setCard('green')"
    >
      <card-icon class="card-icon--warning" />
    </div>
    <span class="card__count">
      {{ cards.green.length }}
    </span>
      <br>
    <!-- Yellow -->
    <div
      :class="{
        'cards__card--clickable': (cards.yellow.length >= 1),
        'cards__card--active': (card == 'yellow')
      }"
      class="cards__card"
      @click="setCard('yellow')"
    >
      <card-icon class="card-icon--severe" />
    </div>
    <span class="card__count">
      {{ cards.yellow.length }}
    </span>
      <br>
    <!-- Red -->
    <div
      :class="{
        'cards__card--clickable': (cards.red.length >= 1),
        'cards__card--active': (card == 'red')
      }"
      class="cards__card"
      @click="setCard('red')"
    >
      <card-icon class="card-icon--fatal" />
    </div>
    <span class="card__count">
      {{ cards.red.length }}
    </span>
  </div>
</template>
<script>
import { CardIcon } from '@tribus/common-icons';

export default {
  components: { CardIcon },
  props: {
    userCards: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      cards: this.userCards,
      card: null,
    };
  },
  computed: {
    msg() {
      return this.card ? this.cards[this.card] : null;
    },
  },
  watch: {
    card() {
      this.$emit('msg', this.msg);
    },
  },
  methods: {
    hasCardType(cardType) {
      return this.cards[cardType] !== undefined;
    },
    setCard(cardType) {
      if (this.hasCardType(cardType)) {
        this.card = cardType;
      }
    },
    resetCard() {
      this.card = null;
    },
  },
};
</script>
