import Vue from 'vue';
import { ToggleButton } from 'vue-js-toggle-button';
import axios from 'axios';
import Criteria from '../components/cms/Criteria.vue';
import Questions from '../components/cms/Questions.vue';

window.addEventListener('DOMContentLoaded', () => {
  const videoEl = '[data-vm="video-manage"]';
  if (document.querySelector(videoEl)) {
    new Vue({
      el: videoEl,
      components: {
        Criteria, Questions, ToggleButton,
      },
      data() {
        return {
          questionsNeeded: true,
        };
      },
      mounted() {
        this.$refs.questions.$on('questions:sync-ordering', (questions) => {
          const videoId = this.$refs.questions.video;
          const endpoint = `/api/v1/group-admin/questions/${videoId}/set-order`;
          axios.post(endpoint, { questions });
        });
      },
    });
  }
});
