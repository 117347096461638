<template>
  <div class="project-list">
    <slot />
    <div class="field__input project-list__field">
      <label class="field__label">Search</label>
      <input
        v-model="search"
        class="form-control project-list__search"
        type="text"
        placeholder="Start typing a project name to filter the list..."
        @input="filterList()"
      >
    </div>
    <table class="project-list__table">
      <thead>
      <th class="text-center">
          Project ID
      </th>
        <th
          ref="header-name"
          class="project-list__th"
        >
          Project Name
        </th>
        <th class="text-center">
          {{clientConfigOffice}}
        </th>
        <th class="text-center">
          Turnstile Provider
        </th>
        <th class="text-center">
          Turnstile Provider ID
        </th>
        <th class="text-center">
            CRM Status
        </th>
        <th class="text-center">
          Live
        </th>
        <th class="project-list__action text-center">
          Action
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(project, key) in chunckList"
          :key="key"
        >
          <td class="project-list__td">
             {{ project.id }}
          </td>
          <td class="project-list__td">
            {{ project.name }}
          </td>
          <td class="project-list__td">
            {{ project.lco.name }}
          </td>
          <td class="project-list__td">
            {{ getTurnstileProviderName(project.turnstile_provider) }}
          </td>
          <td class="project-list__td">
            {{ project.turnstile_provider ? project.turnstile_reference : 'N/A' }}
          </td>
          <td class="project-list__td">
            {{ project.crm_status ? project.crm_status : "N/A" }}
          </td>
          <td class="text-center">
            <tick-svg
              v-if="project.active == 1"
              tick-color="#A6CB77"
            />
            <cross-svg
              v-else
              cross-color="#9B9B9B"
            />
          </td>
          <td class="project-list__td text-center">
            <button
              v-if="project.active == 1"
              class="project-list__button danger"
              @click="checkAction(key, 0)"
            >
              Deactivate
            </button>
            <button
              v-else
              class="project-list__button success"
              @click="checkAction(key, 1)"
            >
              Activate
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ul class="project-list__pagination">
      <li
        v-for="index in generatePagination"
        :key="index"
        :class="{
          'project-list__pagination-item--active':
            index === activePage
        }"
        class="project-list__pagination-item"
      >
        <span @click="setPage(index)">{{ index }}</span>
      </li>
    </ul>
    <div
      v-show="projectModal"
      class="project-list__modal pre-lightbox"
    >
      <div class="pre-lightbox__content project-list__lightbox--content">
        <p>
          <strong>
            Are you sure you want to
            <template v-if="selectedProject.state == 0">
              de
            </template>
            activate this project on mi | pre-enrolment</strong>
        </p>
        <p>{{ selectedProject.name }}</p>
        <div class="pre-lightbox__footer">
          <button
            class="button outline"
            @click="projectModal = false"
          >
            Cancel
          </button>
          <button
            class="button success"
            @click="manageProject()"
          >
            Yes,<template v-if="selectedProject.state == 0">
              de
            </template>
            activate this project
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { post } from 'axios';
import TickSvg from '../ui/TickSvg';
import CrossSvg from '../ui/CrossSvg';
import MagnifySvg from '../ui/MagnifySvg';

export default {
  name: 'ProjectList',
  components: {
    TickSvg,
    CrossSvg,
    MagnifySvg,
  },
  props: {
    projects: {
      type: [Array, Object],
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      dataList: this.projects,
      selectedProject: {
        id: null,
        name: null,
        key: null,
        state: null,
      },
      projectModal: false,
      search: '',
      activePage: 1,
      start: 0,
      chunk: 15,
    };
  },
  computed: {
    chunckList() {
      const chunkedArray = this.dataList.slice(
        this.start,
        this.start + this.chunk,
      );
      return chunkedArray;
    },
    generatePagination() {
      let i;
      let j;
      let chunkCount = 0;
      for (i = 0, j = this.dataList.length; i < j; i += this.chunk) {
        chunkCount++;
      }
      return chunkCount;
    },
    clientConfigOffice() {
      return window.clientConfig['client-office'];
    },
  },
  methods: {
    getTurnstileProviderName(provider) {
      switch (provider) {
        case 'MSITE':
          return 'MSite';
        case 'SITE_SENTINEL':
          return 'Site Sentinel';
        default:
          return 'N/A';
      }
    },
    setPage(page) {
      this.activePage = page;
      const link = page - 1;
      this.start = link * this.chunk;
    },
    filterList() {
      const newList = this.projects.filter(project => project.name
        .toLowerCase()
        .includes(this.search.toLowerCase()));
      this.dataList = newList;
      this.start = 0;
    },
    checkAction(key, state) {
      const { id, name } = this.chunckList[key];
      this.selectedProject = {
        id, key, state, name,
      };
      this.projectModal = true;
    },
    manageProject() {
      const project = this.chunckList[this.selectedProject.key];
      project.active = this.selectedProject.state;
      Object.assign(this.chunckList[this.selectedProject.key], project);
      post(
        `/api/v1/group-admin/projects/${
          this.selectedProject.id
        }/set-status`,
        {
          status: project.active,
        },
      ).catch((err) => {
        console.log(err);
      });
      this.projectModal = false;
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./../../../styles/variables.scss";

.project-list {
    .field__input {
        &:before {
            content: url("data:image/svg+xml, %3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 20 20' style='enable-background:new 0 0 20 20;' xml:space='preserve'%3E%3Cg%3E%3Cpath style='fill: transparent;' d='M12.63,4.2c-1.11-1.12-2.62-1.75-4.19-1.75l0,0c-1.58-0.01-3.1,0.61-4.23,1.72C3.09,5.28,2.46,6.79,2.45,8.37 c-0.01,1.58,0.62,3.1,1.73,4.22c1.11,1.12,2.63,1.76,4.21,1.76c1.58,0,3.1-0.62,4.22-1.74c1.12-1.12,1.75-2.63,1.75-4.22 C14.36,6.83,13.74,5.31,12.63,4.2z M8.26,11.26L4.45,7.03h7.63L8.26,11.26z'/%3E%3Cpath id='outer' style='fill: %239B9B9B;' d='M20,18.18l-4.82-4.8c1.06-1.44,1.64-3.18,1.64-4.99c0-2.23-0.89-4.37-2.47-5.95 c-1.58-1.58-3.72-2.46-5.95-2.46c-2.23,0-4.37,0.89-5.95,2.47C0.87,4.05-0.01,6.19,0,8.42c0.01,2.23,0.9,4.37,2.48,5.94 c1.58,1.57,3.73,2.45,5.96,2.44v0c1.82-0.01,3.57-0.6,5.01-1.68l4.82,4.79L20,18.18z M8.39,14.35c-1.58,0-3.1-0.64-4.21-1.76 c-1.11-1.12-1.74-2.64-1.73-4.22c0.01-1.58,0.64-3.1,1.77-4.21c1.12-1.11,2.65-1.73,4.23-1.72l0,0c1.57,0.01,3.08,0.64,4.19,1.75 c1.11,1.12,1.73,2.63,1.73,4.2c0,1.58-0.63,3.1-1.75,4.22C11.49,13.73,9.97,14.36,8.39,14.35z'/%3E%3Cpolygon id='inner' style='fill: transparent;' points='8.26,11.26 12.08,7.03 4.45,7.03 '/%3E%3C/g%3E%3C/svg%3E");
            position: absolute;
            height: 1rem;
            width: 1rem;
            bottom: 0.7rem;
            left: 0.5rem;
        }
    }

    &__table {
        width: 100%;
        th,
        td {
            border: 1px solid black;
        }

        th {
            padding: 0.8rem;
        }
    }

    &__th {
        width: 50%;
    }

    &__td {
        padding: 0 0.8rem;
    }

    &__action {
        max-width: 10rem;
    }

    &__search {
        font-size: 0.8rem;
        height: 1rem;
        border-radius: 1rem;
        @include media-breakpoint-up(md) {
            width: 50%;
        }
    }

    &__field {
        margin-bottom: 1.5rem;
    }

    &__button {
        border-radius: 1rem;
        margin: 0.5rem 0;
        font-size: 0.8rem;
        color: white;
        width: 100%;
        max-width: 8rem;
        padding: 0.3rem 0.5rem;
        border: white 1px solid;

        &.danger {
            background-color: #c73824;
        }

        &.success {
            background-color: #a6cb77;
        }
    }

    &__lightbox {
        &--content {
            text-align: center;
            padding: 2rem;
        }
    }

    &__pagination {
        padding: 0;
        text-align: center;
        margin-top: 2rem;
    }

    &__pagination-item {
        display: inline-block;
        padding: 1rem;
        color: $dark-green;
        cursor: pointer;

        &--active {
            color: $black;
        }
    }

    .pre-lightbox__footer {
      display: flex;
      align-content: center;
      justify-content: center;

      .button {
        margin-bottom: 0;
      }

      .button.outline {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-right: 1.5rem;
      }
    }
}
</style>
