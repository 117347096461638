<template>
  <div
    :class="{ active: focused, collapsed: !focused }"
    class="project-selector"
    @mouseleave="focused = false"
  >
    <ul>
      <li
        v-show="project == null || focused"
        class="project-selector__item empty"
        @click.prevent="selectPlaceholder()"
      >
        <input
          ref="searchQuery"
          v-model="projectSearch"
          class="project-selector__input"
          type="text"
          placeholder="Start typing to search..."
          @input="filterProjects"
        >
      </li>

      <li
        v-for="item in projects"
        :key="item.id"
        :class="{ selected: project && project.id === item.id }"
        class="project-selector__item"
        @click.prevent="select(item)"
      >
        <h1
          class="project__name"
          v-text="item.name"
        />
        <p
          class="project__description"
          v-text="item.town_city"
        />
      </li>
    </ul>

    <span
      v-if="!focused"
      class="fas fa-chevron-down fa-fw"
    />
  </div>
</template>

<script>
export default {
  props: {
    uProjects: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      project: null,
      focused: false,
      projects: this.uProjects,
      projectSearch: '',
    };
  },
  watch: {
    focused(value) {
      if (value) {
        this.$nextTick(() => this.$refs.searchQuery.focus());
      }
    },
  },
  methods: {
    filterProjects() {
      const newList = this.uProjects.filter(project => project.name
        .toLowerCase()
        .includes(this.projectSearch.toLowerCase()));
      this.projects = newList;
    },
    select(project) {
      if (!this.focused) {
        this.focused = true;
        return;
      }

      this.project = project;
      this.focused = false;

      this.$emit('input', project);
    },
    selectPlaceholder() {
      if (!this.focused) {
        this.focused = true;
        return;
      }

      this.$emit('input', null);
    },
  },
};
</script>
