import Vue from 'vue';
import ReportingTabs from '../components/reporting/ReportingTabs.vue';

window.addEventListener('DOMContentLoaded', () => {
  const csvExportEl = '[data-vm="csv-export"]';
  if (document.querySelector(csvExportEl)) {
    new Vue({
      data: {
        showEnrolmentTab: document.querySelector(csvExportEl).getAttribute('data-show-enrolment-tab') == 1,
        showCardTab: document.querySelector(csvExportEl).getAttribute('data-show-card-tab') == 1,
      },
      render: h => h(ReportingTabs),
    }).$mount(csvExportEl);
  }
});
