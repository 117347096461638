<template>
  <span class="sortSvg">
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 11 12.8"
      style="enable-background:new 0 0 11 12.8;"
      xml:space="preserve"
    >
      <title>Sort</title>
      <desc />
      <polygon
        id="Rectangle"
        class="arrow__up"
        points="5.5,0 11,5.2 0,5.2 "
      />
      <polygon
        id="Rectangle-Copy-8"
        class="arrow__down"
        points="5.5,12.8 0,7.6 11,7.6 "
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'SortSvg',
};
</script>

<style lang="scss">
  .sortSvg {
    svg {
      height: 0.7rem;
      vertical-align: baseline;
    }
    .arrow__up {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: rgba(74, 74, 74, 1);
    }
    .arrow__down {
      fill-rule: evenodd;
      clip-rule: evenodd;
      fill: rgba(74, 74, 74, 1);
    }
    &--desc {
      .arrow__up {
        fill: rgba(74, 74, 74, 0.35);
      }
    }
    &--asc {
      .arrow__down {
        fill: rgba(74, 74, 74, 0.35);
      }
    }
  }
</style>
