import validate from '../directives/Validate';

export default {
  directives: {
    validate,
  },
  data() {
    return {
      errors: {},
    };
  },
  methods: {
    testErrors() {
      return 'mixin loaded';
    },
    /**
         * Set the errors for a form field.
         *
         * @param {String} form
         * @param {String} field
         * @param {Array} errors
         */
    setErrors(form, field, errors) {
      if (this.errors[form] == null) {
        this.errors[form] = {};
      }
      this.$set(this.errors[form], field, errors);
      this.$forceUpdate();
    },
    /**
         * Get a form field's errors.
         *
         * @param {String} form
         * @param {String} field
         *
         * @return {Array}
         */
    getErrors(form, field) {
      return this.errors[form] ? this.errors[form][field] || [] : [];
    },
    /**
         * Get if a form field's errors.
         *
         * @param {String} form
         * @param {String} field
         *
         * @return {Bool}
         */
    hasErrors(form, field) {
      return this.getErrors(form, field).length > 0;
    },
    /**
         * Get if a form error is fresh.
         *
         * @param {String} form
         * @param {String} field
         *
         * @return {Array}
         */
    isFreshError(form, field) {
      return (
        this.errors[form] === undefined
                || this.errors[form][field] === undefined
      );
    },
  },
};
