<template>
  <div class="profile-flags">
    <!-- Medication -->
    <div
      v-if="hasFlagType('medication')"
      :class="{
        'flags__flag--relevant': hasFlagType('medication'),
        'flags__flag--active': flag == 'medication'
      }"
      class="flags__flag"
      @click="setFlag('medication')"
    >
      <medication-icon
        :class="[
          hasFlagType('medication')
            ? 'medication-icon--focus'
            : 'medication-icon--blur'
        ]"
      />
      <h6>Requires daily medication</h6>
    </div>
    <!-- Treatment -->
    <div
      v-if="hasFlagType('condition')"
      :class="{
        'flags__flag--relevant': hasFlagType('condition'),
        'flags__flag--active': flag == 'condition'
      }"
      class="flags__flag"
      @click="setFlag('condition')"
    >
      <treatment-icon
        :class="[
          hasFlagType('condition')
            ? 'treatment-icon--focus'
            : 'treatment-icon--blur'
        ]"
      />
      <h6>Specialist health condition</h6>
    </div>
    <!-- Foreign dialect -->
    <div
      v-if="hasFlagType('translator')"
      :class="{
        'flags__flag--relevant': hasFlagType('translator'),
        'flags__flag--active': flag == 'translator'
      }"
      class="flags__flag"
      @click="setFlag('translator')"
    >
        <div class="foreign-dialect-icon icon-foreign-dialect--focus d-inline-block">
            <div class="icon">
                <img src="/assets/static/icons/translator.png" class="icon-foreign-dialect foreign-dialect-icon--focus" />
            </div>
        </div>
        <h6>Requires a translator</h6>
    </div>
  </div>
</template>
<script>
import { MedicationIcon, TreatmentIcon } from '@tribus/medical-icons';
import { ForeignDialectIcon } from '@tribus/locale-icons';

export default {
  name: 'Flags',
  components: {
    /**
         * Flags (Foreign dialect, Medication, Treatment)
         */
    ForeignDialectIcon, // Locale icons
    MedicationIcon,
    TreatmentIcon, // Medical icons
  },
  props: {
    userFlags: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      flags: JSON.parse(this.userFlags),
      flag: null,
    };
  },
  computed: {
    msg() {
      return this.flag ? this.flags[this.flag] : null;
    },
  },
  watch: {
    flag() {
      this.$emit('msg', this.msg);
    },
  },
  methods: {
    hasFlagType(flagType) {
      // console.log(flagType, this.flags);
      return this.flags[flagType] !== undefined;
    },
    setFlag(flagType) {
      if (this.hasFlagType(flagType)) {
        this.flag = flagType;
      }
    },
    resetFlag() {
      this.flag = null;
    },
  },
};
</script>
