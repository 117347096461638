<template>
  <div class="col-12">
    <div class="row">
      <div class="urn-profile__personal-identification-picture">
        <div
          v-show="serverImage"
          class="webCamVideo__wrapper"
        >
          <img
            :src="serverImage"
            href="#webcam-imageModal"
            data-toggle="modal"
            data-gallery="example-gallery"
            data-img-url="serverImage"
            class="card__image--citb"
          >
          <figure class="upload">
            <div
              id="webcam-imageModal"
              class="modal fade"
              tabindex="-1"
              role="dialog"
              aria-labelledby="myModalLabel"
              aria-hidden="true">
              <div class="modal-dialog">
                <div class="modal-content">
                  <div class="modal-body text-center">
                    <img
                      :src="serverImage"
                      class="rounded mx-auto d-block">
                  </div>
                  <div class="modal-footer">
                    <button
                      class="btn"
                      data-dismiss="modal"
                      aria-hidden="true">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </figure>
          <span
            class="webCamVideo__remove"
            @click="removeServer"
          >X</span>
        </div>
        <div
          v-show="serverImage === null"
          class="webCamVideo__wrapper"
        >
          <img
            v-show="!pictureSaved && componentType !== 'form'"
            class="card__image--noncscs webCamVideo__missing"
            src="/storage/photo/missing.jpeg"
          >
          <div
            id="webCamVideo"
            :class="{
              'field field--upload': componentType == 'form',
              'webCamVideo--photoid': componentType !== 'form'
            }"
          >
            <template v-if="componentType !== 'form'">
              <canvas
                v-show="pictureSaved"
                id="webCamVideo__picture"
                href="#webcam-newImageModal"
                data-toggle="modal"
                data-gallery="example-gallery"
                data-img-url="pictureSaved">
                >
              </canvas>
              <div
                id="webcam-newImageModal"
                class="modal fade"
                tabindex="-1"
                role="dialog"
                aria-labelledby="myModalLabel"
                aria-hidden="true">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-body text-center">
                      <canvas
                        v-show="pictureSaved"
                        id="webCamVideo__picture-lightbox"
                      />
                    </div>
                    <div class="modal-footer">
                      <button
                        class="btn"
                        data-dismiss="modal"
                        aria-hidden="true">Close</button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <div
              v-show="videoOpen"
              class="webCamVideo__lightbox"
            >
              <span
                class="webCamVideo__link webCamVideo__link--close"
                @click.prevent="cancelCamera"
              >X</span>
              <div class="webCamVideo__container">
                <video
                  v-show="videoPlaying"
                  id="webCamVideo__video"
                  src
                />
                <canvas
                  v-show="pictureTaken"
                  id="webCamVideo__canvas"
                />
                <div
                  v-show="showError"
                  class="webCamVideo__error--container"
                >
                  <div id="webCamVideo__error" />
                  <button
                    class="button success"
                    @click.prevent="cancelCamera"
                  >
                    Close camera
                  </button>
                </div>
                <div class="webCamVideo__buttons">
                  <button
                    v-show="videoPlaying"
                    class="button success"
                    @click.prevent="takePicture"
                  >
                    Take Picture
                  </button>
                  <button
                    v-show="pictureTaken"
                    class="button success"
                    @click.prevent="resetPicture"
                  >
                    Take again
                  </button>
                  <button
                    v-show="pictureTaken"
                    class="button success"
                    @click.prevent="saveWebCamPicture"
                  >
                    Save Photo
                  </button>
                </div>
              </div>
            </div>
            <template v-if="componentType == 'form'">
              <canvas
                v-show="pictureSaved"
                id="webCamVideo__picture"
              />
            </template>
            <span
              v-show="pictureSaved"
              class="webCamVideo__link webCamVideo__link--remove"
              @click="removeSaved"
            >Remove</span>
            <input
              v-if="componentType == 'form' && serverImage == null"
              v-model="webCamInput"
              :form="form"
              type="hidden"
              name="photo_id"
            >
          </div>
        </div>
      </div>
      <div class="urn-profile__personal-identification-name-job">
        <slot/>
      </div>
    </div>
    <div
      v-show="!pictureSaved"
      class="row urn-profile__personal-identification-picture-upload">
      <div class="col-sm-12 col-md-3">
        <label class="button success" >
          <input
            id="webCamVideo__fileInput"
            ref="webCamVideoFileInput"
            :form="form"
            name="upload_update_button"
            type="file"
            autocomplete="off"
            @change="runFileChange"
          >
          Select Image
        </label>
      </div>
      <div class="col-sm-12 col-md-3">
        <button
          class="button success"
          @click.prevent="playVideo"
        >
          View webcam
        </button>
      </div>
      <div
        v-show="componentType !== 'form'"
        class="col-sm-12 col-md-6">
        <div class="fact"><span class="label">This operative does not have a photo attached to their
        account. Please provide a headshot photograph.</span> <br ></div>
      </div>
    </div>
  </div>
</template>

<script>
import { eventBus } from '../../app';

export default {
  name: 'WebCamCapture',
  props: {
    image: {
      type: String,
      default: null,
    },
    imageSrc: {
      type: String,
      default: null,
    },
    imageType: {
      type: String,
      default: '',
    },
    form: {
      type: String,
      default: null,
    },
    userid: {
      type: Number,
      default: null,
    },
    componentType: {
      type: String,
      default: 'form',
    },
  },
  data() {
    return {
      vendorUrl: window.URL,
      constraints: (window.constraints = {
        audio: false,
        video: {
          width: 480,
          height: 320,
          aspectRatio: { ideal: 1.7777777778 },
        },
      }),
      showError: false,
      videoPlaying: false,
      pictureTaken: false,
      pictureSaved: false,
      videoOpen: false,
      video: null,
      picture: null,
      canvas: null,
      stream: null,
      webCamInput: '',
      serverImage: null,
    };
  },
  watch: {
    pictureSaved(value) {
      eventBus.$emit('idphoto-change', {
        picture: value,
      });
    },
  },
  mounted() {
    this.$nextTick(function () {
      this.picture = document.querySelector('#webCamVideo__picture');
      this.pictureLightbox = document.querySelector('#webCamVideo__picture-lightbox');

      const canvasEl = this.picture;
      const canvasLightboxEl = this.pictureLightbox;
      if (this.image !== null) {
        const ctx = this.picture.getContext('2d');
        const ctxLightbox = this.pictureLightbox.getContext('2d');
        const img = new Image();
        img.onload = function () {
          canvasEl.width = img.width;
          canvasLightboxEl.width = img.width;
          canvasEl.height = img.height;
          canvasLightboxEl.height = img.height;
          ctx.drawImage(img, 0, 0);
          ctxLightbox.drawImage(img, 0, 0);
        };

        img.src = this.image;
        this.pictureSaved = true;
        this.webCamInput = this.image;
      } else if (this.imageSrc !== null) {
        this.serverImage = this.imageSrc;
        this.pictureSaved = true;
      } else {
        this.pictureSaved = false;
      }
    });
  },
  methods: {
    runFileChange(event) {
      const reader = new FileReader();
      const fileInput = event.target;
      const componentEl = this;
      reader.addEventListener(
        'load',
        () => {
          componentEl.webCamInput = reader.result;
          componentEl.saveFilePicture();
        },
        false,
      );
      if (fileInput.files.length > 0) {
        reader.readAsDataURL(fileInput.files[0]);
        this.pictureSaved = true;
      }
    },
    errorMsg(msg, error) {
      const errorElement = document.querySelector('#webCamVideo__error');
      errorElement.innerHTML += `<p>${msg}</p>`;
      if (typeof error !== 'undefined') {
        console.error(error);
      }
    },
    takePicture() {
      const context = this.canvas.getContext('2d');

      this.videoPlaying = false;
      this.canvas.width = this.video.videoWidth;
      this.canvas.height = this.video.videoHeight;
      context.drawImage(this.video, 0, 0);
      this.pictureTaken = true;
    },
    resetPicture() {
      this.videoPlaying = true;
      this.pictureTaken = false;
    },
    saveWebCamPicture() {
      const context = this.picture.getContext('2d');
      const track = this.stream.getTracks()[0];
      track.stop();
      this.videoOpen = false;
      this.pictureSaved = true;
      this.savePicture(this.canvas, this.picture);
    },
    saveFilePicture() {
      const { picture } = this;
      const ctx = picture.getContext('2d');
      const img = new Image();
      img.onload = function () {
        picture.width = img.width;
        picture.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
      img.src = this.webCamInput;
      if (this.componentType !== 'form') {
        axios.post(`/api/photoid/upload/${this.userid}`, {
          data: this.webCamInput,
        });
      }
    },
    savePicture(src, dest) {
      const context = dest.getContext('2d');
      dest.width = src.width;
      dest.height = src.height;
      context.drawImage(src, 0, 0);
      this.webCamInput = src.toDataURL();
      if (this.componentType !== 'form') {
        axios.post(`/api/photoid/upload/${this.userid}`, {
          data: this.webCamInput,
        });
      }
    },
    cancelCamera() {
      if (this.stream !== null) {
        const track = this.stream.getTracks()[0];
        track.stop();
      }
      this.videoOpen = false;
      this.pictureTaken = false;
    },
    removeSaved() {
      const context = this.picture.getContext('2d');
      context.clearRect(0, 0, this.picture.width, this.picture.height);
      this.picture.width = 0;
      this.picture.height = 0;
      this.pictureSaved = false;
      this.webCamInput = '';
      this.$refs.webCamVideoFileInput.value = null;
      if (this.componentType !== 'form') {
        axios.get(`/api/photoid/remove/${this.userid}`);
      }
    },
    removeServer() {
      this.serverImage = null;
      this.pictureSaved = false;
      this.$refs.webCamVideoFileInput.value = null;
      if (this.componentType !== 'form' && this.imageType === 'web') {
        axios.get(`/api/photoid/remove/${this.userid}`);
      }
    },
    playVideo() {
      this.video = document.querySelector('#webCamVideo__video');
      this.canvas = document.querySelector('#webCamVideo__canvas');
      const context = this.canvas.getContext('2d');
      context.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.canvas.width = 0;
      this.canvas.height = 0;
      this.videoOpen = true;
      this.pictureTaken = false;
      this.showError = false;
      const elementVue = this;

      navigator.mediaDevices
        .getUserMedia(elementVue.constraints)
        .then((stream) => {
          const videoTracks = stream.getVideoTracks();

          // console.log("Got stream with constraints:", elementVue.constraints);
          // console.log("Using video device: " + videoTracks[0].label);
          stream.onremovetrack = function () {
            console.log('Stream ended');
          };
          elementVue.stream = stream;
          elementVue.video.srcObject = stream;
          elementVue.video.play();
          elementVue.videoPlaying = true;
        })
        .catch((error) => {
          elementVue.showError = true;
          if (error.name === 'ConstraintNotSatisfiedError') {
            elementVue.errorMsg(
              `The resolution ${
                elementVue.constraints.video.width.exact
              }x${
                elementVue.constraints.video.height.exact
              } px is not supported by your device.`,
            );
          } else if (
            error.name === 'PermissionDeniedError'
                        || error.name === 'NotAllowedError'
          ) {
            elementVue.errorMsg(
              'Permissions have not been granted to use your camera, '
                                + 'you need to allow the page access to your devices in '
                                + 'order for the webcam to work.',
            );
          }
          elementVue.errorMsg(
            `getUserMedia error: ${error.name}`,
            error,
          );
        });
    },
  },
};
</script>

<style lang="scss">
#webCamVideo {
    max-width: 100%;
    width: 100%;
    p {
        text-align: center;
    }
    &__fileInput {
        display: none;
    }

    #id_photo {
        display: none;
    }
    &__video {
        cursor: pointer;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    &__picture,
    &__picture-lightbox {
        width: 400px;
        max-width: 100%;
        margin-top: 1.5rem;
    }
}
.alert-warning {
    background: #fff2f2;
    color: #000000;

    &--heading {
        color: #ff3932;
        font-weight: 600;
        font-size: 1.1rem;
    }
}

.webCamVideo {
    p {
        text-align: center;
    }
    &__wrapper {
        width: 100%;
        position: relative;
    }
    &__missing {
        display: inline-block;
        vertical-align: bottom;
    }
    &--photoid {
        display: inline-block;
        // min-width: 20rem;
    }
    &__photo {
        max-width: 100%;
    }
    &__lightbox {
        position: fixed;
        z-index: 999;
        width: 100%;
        height: 100%;
        text-align: center;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.8);
    }
    &__container {
        /** Pad the lightbox image */
        max-width: 90%;
        max-height: 80%;
        margin-top: 5%;
        margin-right: auto;
        margin-left: auto;
    }
    &__buttons {
        display: block;
        margin-top: 1rem;
    }
    &__link {
        color: #50d8b9;
        cursor: pointer;
        display: block;
        &--close {
            position: absolute;
            right: 2rem;
            top: 0;
            font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
                sans-serif;
            font-size: 3rem;
        }
    }
    &__error--container {
        background: white;
        margin: 1rem;
        padding: 2rem;
        width: 50%;
        margin: 1rem auto 1rem auto;
    }
    &__input {
        margin-right: auto;
        margin-left: auto;
        display: block;
    }

    &__remove {
        position: absolute;
        color: #fff;
        border-radius: 1rem;
        display: inline-block;
        width: 2.2rem;
        height: 2.2rem;
        line-height: 1.75;
        vertical-align: top;
        background: grey;
        border: .2rem solid #fff;
        font-weight: 600;
        right: -1rem;
        cursor: pointer;
        top: -1rem;
        text-align: center;
    }
}
</style>
