import Vue from 'vue';
import CertificateUpload from '../components/ui/CertificateUpload.vue';
import errorsMixin from '../mixins/Errors';

window.addEventListener('DOMContentLoaded', () => {
  const qualificationsEl = '[data-vm="qualifications"]';
  if (document.querySelector(qualificationsEl)) {
    new Vue({
      el: qualificationsEl,
      components: {
        CertificateUpload,
      },
      mixins: [errorsMixin],
      data() {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return {
          disabledDates: {
            to: yesterday,
          },
        };
      },
    });
  }
});
