const passes = (checks, values) => {
  let pass = true;

  Object.keys(checks).forEach((k) => {
    if (values[k] === checks[k]) {
      return true;
    }
    pass = false;
    return pass;
  });

  return pass;
};

const toggle = (el, checks, values) => {
  if (!passes(checks, values)) {
    if (el.classList.contains('hidden')) {
      return;
    }

    el.classList.add('hidden');

    return;
  }

  if (!el.classList.contains('hidden')) {
    return;
  }

  el.classList.remove('hidden');
};

export default {
  /**
     * Attach the event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  bind(el, binding, node) {
    const checks = Array.isArray(binding.value) ? {} : binding.value;
    const values = {};

    binding.handler = _.debounce((e) => {
      if (undefined === checks[e.target.name]) {
        return;
      }

      values[e.target.name] = e.target.value;

      toggle(el, checks, values);
    }, 25);

    document.body.addEventListener('keyup', binding.handler, false);
    document.body.addEventListener('change', binding.handler, false);

    setTimeout(() => {
      Object.keys(checks).forEach((k) => {
        let el = document.querySelector(`[name="${k}"]`);

        if (el.type === 'radio') {
          el = document.querySelector(`[name="${k}"][checked]`);
        }

        if (el === null) {
          values[k] = null;

          return;
        }

        values[k] = el.value;
      });

      toggle(el, checks, values);
    }, 0);
  },

  /**
     * Remove the un-needed event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  unbind(el, binding, node) {
    if (binding.handler) {
      document.body.removeEventListener('keyup', binding.handler, false);
      document.body.removeEventListener('change', binding.handler, false);
    }
  },
};
