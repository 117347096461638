<template>
  <div class="tabs">
    <ul class="tablist">
      <li
        v-for="(item, key) in uHeaders"
        :key="key"
        :class="{ current: selectedTab == key }"
        @click="updateSelected(key)"
      >
        {{ item }}
      </li>
    </ul>

    <div class="table-responsive">
      <div
        v-show="selectedTab == 0"
        class="tab-content"
      >
        <table class="table tabs__table" v-if="uMembership.length">
          <thead>
            <th class="membership__name">
              Name
            </th>
            <th>Start Date</th>
            <th>Expiry Date</th>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in uMembership"
              id=""
              :key="index"
              class=""
            >
              <td
                scope="row"
                class="membership__name"
              >
                {{ data.Name }} {{ data.Level }}<br>
                <template v-if="data.Occupations.length > 0">
                  <span
                    class="membership__endorsements"
                  >Endorsements</span><br>
                  <span
                    v-for="(endorsement, index) in data
                    .Occupations[0].Recognitions"
                    :key="index"
                  >{{ endorsement.Name }}, </span>
                </template>
              </td>
              <td class="upload__indicator">
                {{ frontEndDateFormat(data.StartDate) }}
              </td>
              <td class="upload__indicator">
                {{ frontEndDateFormat(data.ExpiryDate) }}
              </td>
            </tr>
          </tbody>
        </table>
          <p v-else class="profile-section-heading">No assigned Memberships for this user</p>
      </div>

      <div
        v-show="selectedTab == 1"
        class="tab-content"
      >
        <table class="table tabs__table" v-if="uResults.length">
          <thead>
            <th class="membership__name">
              Name
            </th>
            <th>Type</th>
            <th>Date</th>
            <th>Result</th>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in uResults"
              id=""
              :key="index"
              class=""
            >
              <td
                scope="row"
                class="membership__name"
              >
                {{ data.Name }}
              </td>
              <td class="upload__expiry">
                {{ data.Type }}
              </td>
              <td class="upload__indicator">
                {{ frontEndDateFormat(data.TestDate) }}
              </td>
              <td class="upload__indicator">
                {{ updateResult(data.Grade) }}
              </td>
            </tr>
          </tbody>
        </table>
          <p v-else class="membership__name">No assigned H&E Tests for this user</p>
      </div>

      <div
        v-show="selectedTab == 2"
        class="tab-content"
      >
        <table class="table tabs__table" v-if="uCourses.length">
          <thead>
            <th class="membership__name">
              Title
            </th>
            <th>Scheme</th>
            <th>Exam date</th>
            <th>Grade</th>
          </thead>
          <tbody>
            <tr
              v-for="(data, index) in uCourses"
              id=""
              :key="index"
              class=""
            >
              <td
                scope="row"
                class="membership__name"
              >
                {{ data.Title }}
              </td>
              <td class="upload__expiry">
                {{ data.Scheme }}
              </td>
              <td class="upload__indicator">
                {{ frontEndDateFormat(data.ExamDate) }}
              </td>
              <td class="upload__indicator">
                {{ updateResult(data.Grade) }}
              </td>
            </tr>
          </tbody>
        </table>
        <p v-else class="profile-section-heading">No assigned courses for this user</p>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    uHeaders: {
      type: Array,
      default() {
        return ['Memberships', 'HS&E Tests', 'Courses'];
      },
    },
    uMembership: {
      type: Array,
      default() {
        return [];
      },
    },
    uResults: {
      type: Array,
      default() {
        return [];
      },
    },
    uCourses: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  methods: {
    updateSelected(tab) {
      this.selectedTab = tab;
    },
    frontEndDateFormat(date) {
      return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
    },
    expiryDate(date) {
      return moment(date, 'YYYY-MM-DD')
        .add(5, 'y')
        .format('DD/MM/YYYY');
    },
    updateResult(result) {
      if (result == 'P') return 'Pass';
      return result;
    },
  },
};
</script>
