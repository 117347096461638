import { CircleIcon } from '@tribus/common-icons';
import shortid from 'shortid';
import Answer from './Answer.vue';

/**
 * Answers component
 *
 */
export default {
  name: 'answers',
  components: {
    Answer,
  },
  props: {
    answers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      allAnswers: this.answers || [],
    };
  },
  mounted() {
    this.$on('answer:deleted', (answer) => {
      this.$delete(this.allAnswers, this.allAnswers.findIndex(a => (a.id === answer.id)));
      this.$forceUpdate();
    });
    this.$on('answer:added', (answer) => {
      Object.assign(this.allAnswers[this.allAnswers.length - 1], answer);
      this.$parent.$emit('answer:added');
    });
  },
  methods: {
    answerIndex() {
      return shortid.generate();
    },
    addAnswer() {
      this.allAnswers.push({});
      this.$nextTick().then(() => {
        this.$children.map((a) => {
          if (a.answer == null) {
            this.$parent.$emit('answer:creating');
            a.show();
          }
        });
      });
    },
  },
};
