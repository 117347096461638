<template>
    <div class="results" :class="`results--${mode}`">
      <div v-if="showCount" class="results__summary">
        <span class="summary__count">{{ count }}</span>
         search results for <span class="summary__term">{{ searchTerm }}</span>
      </div>
      <!-- List layout -->
      <div class="results__items" v-if="mode == 'list'" ref="list">
        <slot />
      </div>
      <!-- Table layout -->
      <table class="results__items" v-if="mode == 'table'" ref="table">
        <thead v-if="mode == 'table' && columnHeadings.length > 0" class="results__header">
          <tr class="headings">
            <th v-for="heading in columnHeadings" class="headings__heading">
              {{ heading }}
            </th>
          </tr>
        </thead>
        <tbody>
          <slot />
        </tbody>
      </table>
      <!-- Pagination -->
      <div class="results__pagination">
        <pagination ref="navigator" class="pagination--flat"
         :total="totalResults"
         :per-page="perPage"
         :url="url"
         :current-page="currentPage"
         :side-btns="false" />
      </div>
    </div>
</template>
<script>
import Pagination from './../pagination/Pagination';
import Mixins from '@tribus/component-mixins';

/**
 * Results component
 *
 */
export default {
    name: 'results',
    mixins: [Mixins.Trans, Mixins.vNodeFilter],
    components: {Pagination},
    props: {
        currentPage: {type: Number, default: 1},
        totalResults: {type: Number, default: 0},
        perPage: {type: Number, default: 10},
        searchTerm: {type: String, default: null},
        url: {type: String, default: null},
        showCount: {type: Boolean, default: true},
        mode: {type: String, default: 'list'}, // Modes (list, table)
        columnHeadings: {type: Array, default: function() { return []; }}
    },
    data() {
        return {
            count: 0,
            resultRange: [null, null]
        };
    },
    mounted: function() {
        this.refreshCount();
        this.setRange(1);
    },
    computed: {
        range() {
            let [start, end] = this.resultRange;
            return {start, end};
        }
    },
    methods: {
        refreshCount() {
            this.count = this.countResults();
        },
        countResults() {
            return (this.$slots.default !== undefined) ?
                this.$slots.default.filter(this.vNodeFilter).length : 0;
        },
        setRange(pageNum) {
            let rangeStart = (pageNum * this.perPage) - this.perPage;
            let rangeEnd = rangeStart + this.perPage;
            this.resultRange = [rangeStart, rangeEnd];
        },
        inPaginationRange: function(result) {
            return (this.range.start != null && this.range.end != null)
                ? this.$slots.default
                    .filter(this.vNodeFilter)
                    .slice(this.range.start, this.range.end)
                    .some(item => item == result.$vnode) : false;
        }
    }
};
</script>
<style lang="scss">
// Results
//
// Results component
//
// Markup:
// <div class="results"></div>
//
//
// Styleguide Components.results
.results {
    display: block;
    &--table {
        .results__items {
            display: table;
        }
    }
}
</style>
