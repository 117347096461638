import { Breadcrumbs } from '@tribus/breadcrumbs';
import {
  CircleIcon,
  CardIcon,
  CrossIcon,
  MagnifierIcon,
  VideoPlayIcon,
  PadlockIcon,
  CogIcon,
  TurnstilesIcon,
    WarningIcon,
} from '@tribus/common-icons';
import MiPreBreadcrumbs from '../link/Link.vue';

export default {
  name: 'LinkList',
  components: {
    Breadcrumbs,
    MiPreBreadcrumbs,
    CircleIcon,
    CardIcon,
    CrossIcon,
    MagnifierIcon,
    VideoPlayIcon,
    PadlockIcon,
    CogIcon,
    TurnstilesIcon,
      WarningIcon,
  },
  props: {
    url: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    linkListClass: {
      type: String,
      default: 'link-list',
    },
    LinkListType: {
      type: String,
      default: 'unordered',
    },
    LinkListTitle: {
      type: String,
      default: '',
    },
  },
};
