<template>
  <div>
    <p class="verify-citb__meta">
      Please enter the number shown on the front of your card in the box
      below and click continue. If the system cannot return a record from the CITB database you will be required to provide additional information.
    </p>
    <p class="verify-citb__meta">
      During your pre-enrolment you will be required to upload a copy of
      various documents including, but not limited to your CSCS card and
      applicable training certificates. Please ensure you have these to
      hand.
    </p>
    <p class="verify-citb__meta verify-citb__meta--bold">
      Please be aware that you will not be permitted on to a
      {{ clientConfigName }} site or allowed to pre-enrol without a valid number.
    </p>
    <div class="verify-citb__form">
      <form
        id="verify"
        :action="uRoute"
        autocomplete="off"
        method="GET"
      />
      <div class="form-group__row">
        <div
          :class="{ 'has-danger': errors }"
          class="form-group form-group__half"
        >
          <div class="form-group">
            <input
              v-model="cardNumber"
              form="verify"
              name="citb"
              type="text"
              class="form-control"
              placeholder="CITB Number"
              value=""
              required
            >
            <small
              v-for="(message, field) in errors"
              :key="field"
              class="form-text text-red form-control-feedback field__feedback error"
            >{{ message[0] }}</small>
          </div>
        </div>

        <div class="form-group form-group__half">
          <div class="form-group">
            <input
              v-model="surname"
              form="verify"
              name="surname"
              type="text"
              class="form-control"
              placeholder="Surname"
              value=""
              required
            >
            <small
              class="form-text text-red form-control-feedback field__feedback error"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group form-group__full form-group__buttons">
      <input
        form="verify"
        type="submit"
        class="verify-citb__cta"
        value="Continue"
        @click.prevent="checkCscsMembership"
      >
      <p class="verify-citb__info verify-citb__info--full">
        Don't have a CSCS Membership?
        <a
          :href="uRoute + '?type=noncscs'"
        >Click here.</a>
      </p>
    </div>
    <div
      v-if="citbCheckFailed"
      class="pre-lightbox"
    >
      <div class="pre-lightbox__content">
        <span
          class="verify-citb__close"
          @click="citbCheckFailed = !citbCheckFailed"
        >X</span>
        <h4 class="verify-citb__title verify-citb__title--lightbox">
          <circle-cross-svg /> Membership Not Found
        </h4>
        <p>Unfortunately we could not find your CSCS Membership in the CITB records. This could be due to one of two things:</p>
        <ol class="verify-citb__list">
          <li>
            You may have entered your details incorrectly. <span
              class="verify-citb__link"
              @click="citbCheckFailed = !citbCheckFailed"
            >Click here</span> to go back and try again.
          </li>
          <li>The card type you have entered is not in the CITB database, if this is the case you can continue but will be required to enter additional information.</li>
        </ol>
        <div class="pre-lightbox__footer">
          <span
            class="pre-lightbox__footer--left pre-lightbox__footer--text verify-citb__link"
            @click="citbCheckFailed = !citbCheckFailed"
          >Try again</span>
          <button
            class="button success pre-lightbox__footer--right"
            @click="continueAnyway"
          >
            Non-CITB Card
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleCrossSvg from '../ui/CircleCrossSvg';

export default {
  components: {
    CircleCrossSvg,
  },
  props: {
    uRoute: {
      type: String,
      default: '/',
    },
  },
  data() {
    return {
      citbCheckFailed: false,
      errors: {},
      cardNumber: '',
      surname: '',
    };
  },
  computed: {
    clientConfigName() {
      return window.clientConfig['client-name'];
    },
  },
  methods: {
    selectCitb() {
      this.isCitb = true;
      this.showCitb();
    },
    showCitb() {
      let returnVal = false;
      if (this.errors !== '') {
        returnVal = true;
      } else if (this.isCitb) {
        returnVal = true;
      }

      return returnVal;
    },
    checkCscsMembership() {
      const vueInstance = this;
      axios
        .post(
          '/api/citb-card/search', {
            citb: this.cardNumber,
            surname: this.surname,
          },
        )
        .then((response) => {
          const form = document.querySelector('#verify');
          form.submit();
        })
        .catch((error) => {
          if (error.response.status == 404) {
            vueInstance.citbCheckFailed = true;
          } else if (Object.keys(error.response.data).length > 0) {
            vueInstance.errors = Object.assign({}, error.response.data.errors);
          }
        });
    },
    continueAnyway() {
      window.location.href = `${this.uRoute}?type=noncscs`;
    },
  },
};
</script>
