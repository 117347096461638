import axios from 'axios';
import Mixins from '@tribus/component-mixins';
import { HamburgerIcon } from '@tribus/common-icons';
import {
  CrossIcon, PencilIcon, SquarePlusIcon, TrashcanIcon,
} from '@tribus/common-icons';
import Answers from './Answers.vue';

const randomItem = require('random-item');

/**
 * Question component
 *
 */
export default {
  name: 'question',
  components: {
    Answers,
    HamburgerIcon,
    CrossIcon,
    PencilIcon,
    SquarePlusIcon,
    TrashcanIcon,
  },
  mixins: [
    Mixins.Collapsable,
  ],
  props: {
    identifier: { type: Number, default: null },
    questionText: { type: String, default: 'Untitled' },
    index: { type: Number, default: 0 },
    answers: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    const { identifier, questionText } = this;

    return {
      id: identifier,
      question: questionText,
      creating: false,
      modifying: false,
    };
  },
  mounted() {
    this.$on('answer:creating', () => {
      this.creating = true;
    });
    this.$on('answer:added', () => {
      this.creating = false;
    });
  },
  computed: {
    formData() {
      const { question } = this;
      return { question };
    },
  },
  methods: {
    edit() {
      this.$refs.question.focus();
      this.modifying = true;
    },
    cancelEdit() {
      this.modifying = false;
    },
    update() {
      const endpoint = `/api/v1/group-admin/question/${this.id}/update`;
      axios.post(endpoint, this.formData).then(() => {
        this.modifying = false;
      });
    },
    handleRemove() {
      const confirmed = confirm('Are you sure you want to delete?');
      if (confirmed) {
        this.remove();
      }
    },
    remove() {
      const endpoint = `/api/v1/group-admin/question/${this.id}/delete`;
      axios.post(endpoint).then((res) => {
        this.$parent.$parent.$emit('question:deleted', this);
      });
    },
  },
};
