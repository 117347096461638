var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-flags"},[(_vm.hasFlagType('medication'))?_c('div',{staticClass:"flags__flag",class:{
      'flags__flag--relevant': _vm.hasFlagType('medication'),
      'flags__flag--active': _vm.flag == 'medication'
    },on:{"click":function($event){return _vm.setFlag('medication')}}},[_c('medication-icon',{class:[
        _vm.hasFlagType('medication')
          ? 'medication-icon--focus'
          : 'medication-icon--blur'
      ]}),_vm._v(" "),_c('h6',[_vm._v("Requires daily medication")])],1):_vm._e(),_vm._v(" "),(_vm.hasFlagType('condition'))?_c('div',{staticClass:"flags__flag",class:{
      'flags__flag--relevant': _vm.hasFlagType('condition'),
      'flags__flag--active': _vm.flag == 'condition'
    },on:{"click":function($event){return _vm.setFlag('condition')}}},[_c('treatment-icon',{class:[
        _vm.hasFlagType('condition')
          ? 'treatment-icon--focus'
          : 'treatment-icon--blur'
      ]}),_vm._v(" "),_c('h6',[_vm._v("Specialist health condition")])],1):_vm._e(),_vm._v(" "),(_vm.hasFlagType('translator'))?_c('div',{staticClass:"flags__flag",class:{
      'flags__flag--relevant': _vm.hasFlagType('translator'),
      'flags__flag--active': _vm.flag == 'translator'
    },on:{"click":function($event){return _vm.setFlag('translator')}}},[_vm._m(0),_vm._v(" "),_c('h6',[_vm._v("Requires a translator")])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"foreign-dialect-icon icon-foreign-dialect--focus d-inline-block"},[_c('div',{staticClass:"icon"},[_c('img',{staticClass:"icon-foreign-dialect foreign-dialect-icon--focus",attrs:{"src":"/assets/static/icons/translator.png"}})])])
}]

export { render, staticRenderFns }