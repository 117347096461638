<template>
  <div class="cert-upload">
    <div
      v-for="(cert, certIndex) in uploaded"
      :key="certIndex"
      class="cert-upload__existing"
    >
      <div class="cert-upload__form">
        <div class="row">
          <div class="cert-upload__cert-choice col">
            <div class="field field--select inline">
              <label class="field__label">Certification Type</label>
              <template v-if="cert.val">
                {{ getCert(cert.val).name }}
              </template>
              <template v-else>
                <div class="field__input">
                  <select
                    id="certificate_type"
                    ref="newCertType"
                    placeholder="Please Select"
                    autocomplete="off"
                    class="input cert-upload__certpicker"
                    name="certificate_type"
                  >
                    <option
                      value=""
                    >
                      Please select
                    </option>
                    <option
                      v-for="(choices,
                              index) in filteredChoices"
                      :key="index"
                      :value="choices.val"
                    >
                      {{ choices.name }}
                    </option>
                  </select>
                  <span class="fas fa-chevron-down" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="cert-upload__expiry-choice col-12 col-md-6 col-lg-8"
          >
            <label class="field__label">Expiry date</label>
            <div class="cert-upload__dates row">
              <div class="field field--select col-sm-4">
                <div class="field__input">
                  <select
                    :ref="cert.val ? cert.val + 'Day' : 'newDay'"
                    placeholder="Please Select"
                    autocomplete="off"
                    class="input cert-upload__datepicker"
                  >
                    <option value="">Please select</option>
                    <option value="n/a">N/A</option>
                    <option
                      v-for="value in 31"
                      :key="value"
                      :value="value"
                      :selected="cert.expiryDate && cert.expiryDate.date() === value"
                    >
                      {{ value }}
                    </option>
                  </select>
                  <span class="fas fa-chevron-down" />
                </div>
              </div>
              <div class="field field--select col-sm-4">
                <div class="field__input">
                  <select
                    :ref="cert.val ? cert.val + 'Month' : 'newMonth'"
                    placeholder="Please Select"
                    autocomplete="off"
                    class="input cert-upload__datepicker"
                  >
                    <option value="">Please select</option>
                    <option value="n/a">N/A</option>
                    <option
                      v-for="value in 12"
                      :key="value"
                      :value="value"
                      :selected="cert.expiryDate && (cert.expiryDate.month() + 1) === value"
                    >
                      {{ value }}
                    </option>
                  </select>
                  <span class="fas fa-chevron-down" />
                </div>
              </div>
              <div class="field field--select col-sm-4">
                <div class="field__input">
                  <select

                    :ref="cert.val ? cert.val + 'Year' : 'newYear'"
                    placeholder="Please Select"
                    autocomplete="off"
                    class="input cert-upload__datepicker"
                  >
                    <option value="">Please select</option>
                    <option value="n/a">N/A</option>
                    <option
                      v-for="value in years"
                      :key="value"
                      :value="value"
                      :selected="cert.expiryDate && cert.expiryDate.year() === value"
                    >
                      {{ value }}
                    </option>
                  </select>
                  <span class="fas fa-chevron-down" />
                </div>
              </div>
            </div>
          </div>
          <div class="cert-upload__file col-12 col-md-6 col-lg-4">
            <label class="field__label">Upload Certificate</label>
            <template v-if="cert.val">
              <button
                class="field__upload"
                @click.prevent="handleFileClick(cert.val + 'File')"
              >
                Select File
              </button>
              <input
                :ref="cert.val + 'File'"
                type="file"
                multiple
                @change="handleFileUpload($event, cert.val)"
              >
            </template>
            <template v-else>
              <button
                class="field__upload"
                @click.prevent="handleFileClick('newFile')"
              >
                Select File
              </button>
              <input
                ref="newFile"
                type="file"
                multiple
                @change="handleNewFileUpload($event, certIndex)"
              >
            </template>
          </div>
        </div>
      </div>
      <div class="cert-upload__uploaded-files">
        <div
          v-for="(file, fileIndex) in cert.files"
          :key="fileIndex"
        >
          <a
            :href="file.url"
          >{{ file.name }} -
            <strong>
              <template v-if="file.expiry_date === 'Expired'">Expired</template>
              <template v-else-if="file.expiry_date !== null">{{
                file.expiry_date
              }}</template>
              <template
                v-else
              >
                Expiry N/A
              </template>
            </strong>
          </a>
          <span
            @click="removeSingleFile(file.id, cert.val, certIndex)"
          >
            <img
              src="/static/remove-btn--dark.svg"
              class="cert-upload__remove-single"
          ></span>
        </div>
      </div>
      <template v-if="cert.files.length > 0">
        <img
          src="/static/remove-btn--dark.svg"
          class="cert-upload__remove-all"
          @click="removeAllFiles(cert.val, certIndex)"
        >
      </template>
    </div>
    <button
      v-if="adding === false"
      id="add_certificate_btn"
      class="btn btn-secondary"
      name="add_certificate_btn"
      @click.prevent="addNewCert"
    >
      Add
      <template v-if="uploaded.length > 0">
        Another
      </template>
      Certificate
    </button>
  </div>
</template>

<script>
import moment from 'moment';
import { eventBus } from '../../app';

export default {
  name: 'CertificateUpload',
  props: {
    uid: {
      type: String,
      default: null,
      required: true,
    },
    files: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      adding: false,
      certChoices: [
        { val: 'ipaf', name: 'IPAF' },
        { val: 'aitt', name: 'AITT' },
        { val: 'swqr', name: 'SWQR' },
        { val: 'pasma', name: 'PASMA' },
        { val: 'smsts', name: 'SMSTS' },
        { val: 'irata', name: 'IRATA' },
        { val: 'rtitb', name: 'RTITB' },
        { val: 'itssar', name: 'ITSSAR' },
        { val: 'sentinel', name: 'Sentinel' },
        { val: 'first_aid', name: 'First Aid' },
        { val: 'streetworks', name: 'Streetworks' },
        { val: 'confined_spaces', name: 'Confined Spaces' },
        { val: 'npors_or_non_cscs', name: 'NPORS / Non CSCS' },
        { val: 'gas_safe_register', name: 'Gas Safe Register' },
        { val: 'sssts', name: 'Site Safety Supervisor (SSSTS)' },
        { val: 'asbestos_awareness', name: 'Asbestos Awareness' },
        { val: 'seats', name: 'SEATS' },
        { val: 'face_fit', name: 'Face Fit certifications' },
        { val: 'mental_health_first_aid', name: 'Mental Health First Aider' },
        { val: 'fire_warden', name: 'Fire Warden' },
        { val: 'fire_coordinator', name: 'Fire Co-ordinator' },
        { val: '360vr_service_avoidance', name: '360VR service avoidance training' },
        { val: 'c_and_g_training', name: 'CAT & Genny Training' },
        { val: 'other', name: 'Other' },
      ],
      uploaded: [],
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2012 },
        (value, index) => 2019 + index,
      );
    },
    filteredChoices() {
      return this.certChoices.filter((cert) => {
        const findUpload = this.uploaded.find(upload => upload.val === cert.val);
        return typeof findUpload === 'undefined';
      });
    },
  },
  mounted() {
    this.certChoices.forEach((choice) => {
      if (this.files[choice.val]) {
        const files = this.files[choice.val];
        const file = files.length > 0 ? files[0] : null;

        if (!file) {
          return;
        }

        const date = moment(file.expiry_date, 'DD-MM-YYYY');

        this.uploaded.push({
          val: choice.val,
          files,
          expiryDate: date,
        });
      }
    });

    eventBus.$emit('certs-updated', {
      uploaded: this.uploaded,
    });
  },
  methods: {
    addNewCert() {
      this.uploaded.push({
        val: null,
        files: [],
        expiryDate: null,
      });
      this.adding = true;
    },
    handleNewFileUpload(event, certIndex) {
      const certTypeRef = this.$refs.newCertType[0];
      const certTypeIndex = certTypeRef.selectedIndex;
      const option = certTypeRef.options[certTypeIndex].value;

      this.uploaded[certIndex].val = option;

      const fileInput = event.target;

      for (let i = 0; i < fileInput.files.length; i++) {
        const formData = this.manageCertFormData('new');
        formData.set('file', fileInput.files[i]);
        this.uploadCert(formData);
      }

      event.target.value = '';
      this.adding = false;
    },
    handleFileUpload(event, certVal) {
      const fileInput = event.target;

      for (let i = 0; i < fileInput.files.length; i++) {
        const formData = this.manageCertFormData(certVal);
        formData.set('file', fileInput.files[i]);

        this.uploadCert(formData);
      }

      event.target.value = '';
    },
    manageCertFormData(certVal) {
      const formData = new FormData();
      formData.append(
        'cert_expiry_date_day',
        this.$refs[`${certVal}Day`][0].options[
          this.$refs[`${certVal}Day`][0].selectedIndex
        ].value,
      );
      formData.append(
        'cert_expiry_date_month',
        this.$refs[`${certVal}Month`][0].options[
          this.$refs[`${certVal}Month`][0].selectedIndex
        ].value,
      );
      formData.append(
        'cert_expiry_date_year',
        this.$refs[`${certVal}Year`][0].options[
          this.$refs[`${certVal}Year`][0].selectedIndex
        ].value,
      );
      if (certVal == 'new') {
        formData.append(
          'type',
          this.$refs[`${certVal}CertType`][0].options[
            this.$refs[`${certVal}CertType`][0].selectedIndex
          ].value,
        );
      } else {
        formData.append('type', certVal);
      }

      return formData;
    },
    handleFileClick(refName) {
      this.$refs[refName][0]
        ? this.$refs[refName][0].click()
        : this.$refs[refName].click();
    },
    getCert(val) {
      return this.certChoices.find(cert => cert.val === val);
    },
    getErrors(errs) {
      let errorList = '';
      for (const prop in errs) {
        if (Object.prototype.hasOwnProperty.call(errs, prop)) {
          if (Array.isArray(errs[prop])) {
            errorList += this.getErrors(errs[prop]);
          } else {
            errorList += `${errs[prop]}\n`;
          }
        }
      }

      return errorList;
    },
    uploadCert(formData) {
      formData.append('uid', this.uid);
      axios
        .post(
          '/api/certificate/upload',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          {
            token: window.Laravel.csrfToken,
          },
        )
        .then((response) => {
          const findUpload = this.uploaded.find(upload => (
            upload.val === response.data.data.attributes.type
          ));
          const file = {
            id: response.data.data.attributes.id,
            name: response.data.data.attributes.name,
            url: response.data.data.attributes.url,
            expiry_date: response.data.data.attributes.expiry_date,
          };
          if (findUpload) {
            findUpload.files.push(file);
          } else {
            this.uploaded.push({
              val: response.data.data.attributes.type,
              files: [file],
            });
          }
        })
        .catch((err) => {
          if (err.response.status !== 500) {
            alert(this.getErrors(err.response.data.errors));
          } else {
            alert('There has been a fatal error. Please contact support');
          }
        }).finally(() => {
          eventBus.$emit('certs-updated', {
            uploaded: this.uploaded,
          });
        });
    },
    removeAllFiles(type, certIndex) {
      if (
        confirm(
          `Are you sure you want to delete all the documents for ${
            this.getCert(type).name
          }`,
        )
      ) {
        this.uploaded[certIndex].files.forEach((file) => {
          this.removeCert(file.id, type, certIndex);
        });
      }
    },
    removeSingleFile(id, type, certIndex) {
      if (confirm('Are you sure you want to delete this file?')) {
        this.removeCert(id, type, certIndex);
      }
    },
    removeCert(id, type, certIndex) {
      const owner_id = this.uid;
      const formData = new FormData();
      formData.append('owner_id', owner_id);
      formData.append('type', type);
      formData.append('id', id);

      axios
        .post(
          '/api/certificate/remove',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
          {
            token: window.Laravel.csrfToken,
          },
        )
        .then((response) => {
          const newCertFileList = this.uploaded[
            certIndex
          ].files.filter(file => file.id !== response.data.data.attributes.id);
          if (newCertFileList.length === 0) {
            this.uploaded.splice(certIndex, 1);
          } else {
            this.uploaded[certIndex].files = newCertFileList;
          }
        })
        .catch((err) => {
          alert(this.getErrors(err.response.data.errors));
        }).finally(() => {
          eventBus.$emit('certs-updated', {
            uploaded: this.uploaded,
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./../../../styles/variables.scss";

.cert-upload {
    text-align: left;

    &__form {
        .row {
            margin-bottom: 1rem;
        }
    }

    &__new,
    &__existing {
        border: 1px solid $gray-light;
        padding: 2rem;
        margin-bottom: 1rem;
        position: relative;
        background-color: white;
    }

    &__cert-choice {
        .field--select {
            max-width: 100%;
            padding: 0;
        }
    }

    &__expiry-choice {
        .field--select {
            padding: 0;
            flex: 0 0 33.3%;
            &:first-of-type {
                padding-left: 15px;
            }
        }
    }

    &__file {
        input {
            display: none;
        }
    }

    &__uploaded-files {
        text-align: right;
        margin-top: 1rem;
    }

    &__remove-all {
        position: absolute;
        top: -0.7rem;
        right: -0.7rem;
        width: 1.5rem;
        height: 1.5rem;
        background-color: white;
        cursor: pointer;
    }

    &__remove-single {
        cursor: pointer;
    }

    .field--select &__datepicker {
        padding: 1rem;
    }

    .field__label {
        display: block;
    }

    .field__upload {
        width: 100%;
    }
}
</style>
