import axios from 'axios';
import Vue from 'vue';
import cssVars from 'css-vars-ponyfill';
import Playlist from '../components/cms/Playlist.vue';

window.addEventListener('DOMContentLoaded', () => {
  const videosEl = '[data-vm="video-management"]';
  if (document.querySelector(videosEl)) {
    new Vue({
      el: videosEl,
      components: {
        Playlist,
      },
      mounted() {
        this.$refs.videos.$on('playlist:sync-ordering', (videos) => {
          const endpoint = '/api/v1/group-admin/videos/set-order';
          axios.post(endpoint, { videos });
        });
        Vue.nextTick().then(() => {
          cssVars();
        });
      },
    });
  }
});
