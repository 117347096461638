<template>
  <ul class="pagination" v-show="total > perPage">
    <!-- Previous page -->
    <li class="pagination-item">
      <a class="item__btn item__btn--reduce"
        :href="url + '&page=' + (currentPage - 1)"
        :disabled="isInFirstPage"
        aria-label="Go to previous page">
        <span>Previous</span>
      </a>
    </li>

    <!-- Page numbers -->
    <li v-for="page in pages" class="pagination-item">
      <a class="item__btn item__btn--number"
        type="button"
         :href="url + '&page=' + page.name"
        :class="{ active: isPageActive(page.name) }"
        :aria-label="`Go to page number ${page.name}`">
        {{ page.name }}
      </a>
    </li>

    <!-- Next page -->
    <li class="pagination-item">
      <a class="item__btn item__btn--increment"
         :href="url + '&page=' + (currentPage + 1)"
        :disabled="isInLastPage"
        aria-label="Go to next page">
        <span>Next</span>
      </a>
    </li>
  </ul>
</template>
<script>
import Mixins from '@tribus/component-mixins';
// Supported locales
/**
 * Pagination component
 *
 */
export default {
    name: 'pagination',
    props: {
        maxVisibleButtons: {type: Number, required: false, default: 3},
        total: {type: Number, required: true},
        url: {type: String, default: null},
        perPage: { type: Number, default: 10},
        currentPage: {type: Number, default: 1},
        sideBtns: {type: Boolean, default: true}
    },
    data() {
        return {
            page: null
        };
    },
    mounted: function() {
        this.page = this.currentPage;
    },
    computed: {
        startPage() {
            let page;
            if (this.page === this.totalPages) {
                page = this.totalPages - this.maxVisibleButtons + 1;
            } else {
                page = this.page - 1;
            }

            return (page > 1) ? page : 1;
        },
        endPage() {
            return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        },
        pages() {
            const range = [];

            for (let i = this.startPage; i <= this.endPage; i+= 1 ) {
                range.push({
                    name: i,
                    isDisabled: i === this.page
                });
            }

            return range;
        },
        isInFirstPage() {
            return this.page === 1;
        },
        isInLastPage() {
            return this.page === this.totalPages;
        },
        totalPages() {
            return Math.ceil(this.total / this.perPage);
        }
    },
    methods: {
        isPageActive(page) {
            return this.currentPage === page;
        }
    }
};
</script>
<style lang="scss">
@import 'node_modules/@tribus/style-variables/font-sizes';
@import 'node_modules/@tribus/style-variables/gutter';

$white: #ffffff;
$grey: #d1d1d1;

// Pagination
//
// Pagination component
//
// Markup:
// <div class="pagination"></div>
//
//
// Styleguide Components.pagination
.pagination {
    --accent-color: #4AAE9B;
    align-items: center;
    display: flex;
    justify-content: center;
    list-style-type: none;
    margin-left: -20px;

    .pagination-item {
        .item__btn {
            background: none;
        }
    }

    /**
      * Variations
      */
    // Hide disabled
    &--relevant {
        .pagination-item {
            .item__btn {
                &--reduce, &--increment {
                    &[disabled=disabled] {
                        display: none;
                    }
                }
            }
        }
    }
    // Flat UI
    &--flat {
        .pagination-item {
            .item__btn {
                border: none;
                color: var(--accent-color);
                font-size: $font-size-md;
                font-weight: bold;

                &[disabled=disabled] {
                    color: $grey;
                }
                &--number {
                    margin: $gutter-size-mc;
                }
                &--reduce, &--increment {
                    background: none;
                }
                &--reduce:before, &--increment:after {
                    font-size: $font-size-lg;
                    font-weight: bold;
                    padding: 0 $gutter-size-xs;
                }
                &--reduce {
                    margin-right: $gutter-size-xs;
                    &:before {
                        content: '‹';
                    }
                }
                &--increment {
                    margin-left: $gutter-size-xs;
                    &:after {
                        content: '›';
                    }
                }
            }
            .item__btn.active {
                border-radius: $gutter-size-mn;
                color: $white;
            }
        }
    }
    &-item {
        display: inline-block;
        text-align: center;

        .item__btn {
            cursor: pointer;
            outline: none;
            &:focus, &:active {
                outline: none;
            }
            &[disabled=disabled] {
                cursor: auto;
            }
        }
        .item__btn.active {
            background-color: var(--accent-color);
            color: $white;
        }
    }
}
</style>
