<script>
export default {
    name: "ConditionalSection",
    props: {
        defaultState: { type: Boolean, default: true },
    },
    data() {
        return {
            toggle: this.defaultState,
        };
    },
    methods: {
        toggleVisibility(){
            this.toggle = !this.toggle;
        },
        setVisibility(val){
            this.toggle = val;
        }
    },
    created() {
        this.setVisibility(this.defaultState);
    },
}
</script>

<template>
<div>
    <slot :toggle="toggleVisibility" :set="setVisibility" :show="toggle"></slot>
</div>
</template>

<style scoped lang="scss">

</style>
