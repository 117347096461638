<style scoped>
.action-link {
    cursor: pointer;
}

.m-b-none {
    margin-bottom: 0;
}
</style>

<template>
  <div>
    <div v-if="tokens.length > 0">
      <div class="panel panel-default">
        <div class="panel-heading">
          Authorized Applications - an external system which makes a
          request to be authorised to use pre-enrolment data.
        </div>
        <div class="panel-heading">
          e.g. Instagram requesting authorisation to access Twitter's
          api to enable direct tweeting from the app.
        </div>

        <div class="panel-body">
          <!-- Authorized Tokens -->
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Scopes (data splits)</th>
                  <th />
                </tr>
              </thead>

              <tbody>
                <tr
                  v-for="token in tokens"
                  :key="token.key"
                >
                  <!-- Client Name -->
                  <td style="vertical-align: middle;">
                    {{ token.client.name }}
                  </td>

                  <!-- Scopes -->
                  <td style="vertical-align: middle;">
                    <span v-if="token.scopes.length > 0">
                      {{ token.scopes.join(", ") }}
                    </span>
                  </td>

                  <!-- Revoke Button -->
                  <td style="vertical-align: middle;">
                    <a
                      class="action-link text-danger"
                      @click="revoke(token)"
                    >
                      Revoke
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /*
     * The component's data.
     */
  data() {
    return {
      tokens: [],
    };
  },

  /**
     * Prepare the component (Vue 1.x).
     */
  ready() {
    this.prepareComponent();
  },

  /**
     * Prepare the component (Vue 2.x).
     */
  mounted() {
    this.prepareComponent();
  },

  methods: {
    /**
         * Prepare the component (Vue 2.x).
         */
    prepareComponent() {
      this.getTokens();
    },

    /**
         * Get all of the authorized tokens for the user.
         */
    getTokens() {
      axios.get('/oauth/tokens').then((response) => {
        this.tokens = response.data;
      });
    },

    /**
         * Revoke the given token.
         */
    revoke(token) {
      axios.delete(`/oauth/tokens/${token.id}`).then((response) => {
        this.getTokens();
      });
    },
  },
};
</script>
