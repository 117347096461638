import axios from 'axios';
import draggable from 'vuedraggable';
import { SquarePlusIcon } from '@tribus/common-icons';
import Question from './Question.vue';

/**
 * Questions component
 *
 */
export default {
  name: 'questions',
  components: {
    draggable,
    Question,
    SquarePlusIcon,
  },
  props: {
    video: { type: Number, required: true },
    minQuestions: { type: Number, default: 0 },
    minAnswers: { type: Number, default: 0 },
    questionsNeeded: { type: Boolean, default: true },
    videoLive: { type: Boolean, default: false },
  },
  data() {
    return {
      questions: [],
      question: null,
    };
  },
  computed: {
    hasMinAnswers() {
      return this.questions.every(question => (question.answers != undefined ? question.answers.length : 0) >= this.minAnswers);
    },
    hasMinQuestions() {
      return this.questions.length >= this.minQuestions;
    },
  },
  mounted() {
    this.syncQuestions();
    this.$refs.sortable.$on('sort', this.syncQuestionOrdering);
    this.$on('question:deleted', (question) => {
      this.$delete(this.questions, this.questions.findIndex(q => (q.id == question.id)));
    });
  },
  methods: {
    syncQuestions() {
      fetch(`/group-admin/questions/${this.video}`)
        .then(res => res.json())
        .then((data) => {
          const { questions } = data;
          Object.entries(questions).map(([i, question]) => {
            this.questions.push(question);
          });
        });
    },
    syncQuestionOrdering() {
      const questions = this.questions.map(video => video.id);
      this.$emit('questions:sync-ordering', questions);
    },
    create() {
      this.question = '';
      this.$nextTick().then(this.$refs.question.focus());
    },
    createQuestion() {
      const endpoint = '/api/v1/group-admin/question';
      const { question, video } = this;
      axios.post(endpoint, { question, video_id: video }).then((res) => {
        const { data } = res;
        const q = data.resource;
        this.questions.push(q);
        this.question = null;
      });
    },
  },
};
