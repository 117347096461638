<template>
  <component :is="$parent.mode == 'list' ? 'div' : 'tr'" v-show="inPagination" class="result">
    <slot />
  </component>
</template>
<script>
/**
 * Result component
 *
 */
export default {
    name: 'result',
    computed: {
        inPagination() {
            return (this.$parent.inPaginationRange !== undefined)
                ? this.$parent.inPaginationRange(this) : false;
        }
    }
};
</script>
<style lang="scss">
// Result
//
// Result component
//
// Markup:
// <div class="result"></div>
//
//
// Styleguide Components.result
.results--list {
    .result {
        display: block;
        margin: 20px 0;
    }
}

</style>
