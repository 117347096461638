<template>
  <span class="cardSvg">
    <svg
      width="15px"
      height="19px"
      viewBox="0 0 15 19"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 52.4 (67378) - http://www.bohemiancoding.com/sketch -->
      <title>Card</title>
      <desc>Created with Sketch.</desc>
      <g
        id="3.0b---Pre---Enrolment---Desktop---Logged-In---Admin---Different-Cards"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="1.1a---Admin---Verify-URN---Operative-Returned-Unverified-(Supported-Card)"
          transform="translate(-470.000000, -933.000000)"
        >
          <g
            id="Profile-Summary---Expanded"
            transform="translate(388.000000, 725.000000)"
          >
            <g
              id="Group-10"
              transform="translate(49.000000, 0.000000)"
            >
              <g>
                <g transform="translate(33.000000, 205.000000)">
                  <g
                    id="Group-8"
                    transform="translate(0.000000, 3.000000)"
                  >
                    <g id="Card">
                      <path
                        id="inner"
                        :style="'fill: '+cardColor"
                        d="M10.3933983,0 L15,4.60660172 L15,17 C15,18.1045695 14.1045695,19 13,19 L2,19 C0.8954305,19 1.3527075e-16,18.1045695 0,17 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 L10.3933983,0 Z"
                      />
                      <path
                        id="outer"
                        d="M10.3933983,-5.04870979e-29 L15,4.60660172 L15,5 L10,5 L10,-5.04870979e-29 L10.3933983,-5.04870979e-29 Z"
                        fill="#000000"
                        opacity="0.2"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'CardSvg',
  props: {
    cardColor: {
      default: '#CD321E',
      type: String,
    },
  },
  mounted() {
  },
};
</script>

<style>
  .cardSvg svg {
    vertical-align: text-bottom;
  }
</style>
