<template>
  <div class="survey-banner">
    <div class="survey-banner__text">
      <p class="survey-banner__text__copy">
        We are always looking to improve our pre-enrolment process, please tell us about your experience today.
        <em>The survey will only take approx. 2 minutes to complete and is optional</em>.
      </p>
    </div>
    <div class="survey-banner__button">
      <p class="survey-banner__button__copy">
        <a href="https://www.surveymonkey.co.uk/r/D3JGW6R">Take the survey</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SurveyBanner',
};
</script>

<style lang="scss">
.survey-banner {
  background-color: #4a4a4a;
  color: #e4e4e4;
  font-size: 0.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;

  &__text,
  &__button {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: inline-block;
    padding-bottom: 15px;
    padding-top: 15px;
    &__copy {
      margin: 0;
    }
  }

  &__text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    background-color: #4a4a4a;
  }

  &__button {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    background-color: #1e1e1e;
    text-align: center;

    a {
      color: #e4e4e4;
    }
  }

  &__img {
    max-width: 1rem;
    margin-right: 0.5rem;
    vertical-align: text-bottom;
  }
}
</style>
