<template>
    <div
        v-show="adminModal"
        class="modal fade"
        id="userModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <p>
                        <strong>
                            Your account has expired. You will need to re-enrol before you can verify operatives to site
                        </strong>
                    </p>
                </div>
                <div class="modal-footer">
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-dismiss="modal"
                    >
                        Proceed
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
    name: 'AdminModal',
    data() {
        return {
            adminModal: false,
        };
    },
};
</script>
