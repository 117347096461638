<template>
  <div
    v-if="checkBrowser()"
    class="browser-check"
  >
    <div class="browser-check__text">
      <p class="browser-check__text__copy">
        <strong>
          You appear to be using an unsupported browser.
        </strong>
        For the best experience please upgrade and use the latest
        version of chrome
      </p>
    </div>
    <div class="browser-check__button">
      <p class="browser-check__button__copy">
        <a
          href="https://www.google.com/chrome/browser/desktop/index.html"
        ><img
          :src="browserCheckImg.src"
          :title="browserCheckImg.title"
          :class="browserCheckImg.image_class"
          alt=""
        >Get Chrome</a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    browserCheckImg: {
      type: Object,
      default() {
        return {
          src: '/assets/static/Google_Chrome_icon.svg',
          title: 'google chrome',
          image_class: 'browser-check__img',
        };
      },
    },
  },
  data() {
    return {
      version: false,
    };
  },
  methods: {
    checkBrowser() {
      const ua = window.navigator.userAgent;
      // Test values; Uncomment to check result …

      // IE 10
      // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

      // IE 11
      // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

      // Edge 12 (Spartan)
      // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

      // Edge 13
      // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

      const msie = ua.indexOf('MSIE ');
      if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(
          ua.substring(msie + 5, ua.indexOf('.', msie)),
          10,
        );
      }

      const trident = ua.indexOf('Trident/');
      if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
      }

      const edge = ua.indexOf('Edge/');
      if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(
          ua.substring(edge + 5, ua.indexOf('.', edge)),
          10,
        );
      }

      // other browser
      return false;
    },
  },
};
</script>

<style lang="scss">
.browser-check {
  background-color: #4A4A4A;
  color: #E4E4E4;
  font-size: 0.8rem;
      display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;


  &__text,
  &__button {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    display: inline-block;
    padding-bottom: 15px;
    padding-top: 15px;
    &__copy {
      margin: 0;
    }
  }

  &__text {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.33333333%;
    flex: 0 0 83.33333333%;
    max-width: 83.33333333%;
    background-color: #4A4A4A;
  }

  &__button {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.66666667%;
    flex: 0 0 16.66666667%;
    max-width: 16.66666667%;
    background-color: #1E1E1E;
    text-align: center;

    a {
      color: #E4E4E4;
    }
  }

  &__img {
    max-width: 1rem;
    margin-right: 0.5rem;
    vertical-align: text-bottom;
  }
}
</style>
