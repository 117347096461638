import axios from 'axios';
import Mixins from '@tribus/component-mixins';
import {
  CrossIcon, PencilIcon, SquarePlusIcon, TrashcanIcon,
} from '@tribus/common-icons';

/**
 * Answer component
 *
 */
export default {
  name: 'answer',
  mixins: [
    Mixins.Collapsable,
  ],
  components: {
    CrossIcon, PencilIcon, SquarePlusIcon, TrashcanIcon,
  },
  props: {
    index: { type: Number, default: 0 },
    identifier: { type: Number, default: null },
    answerText: { type: String, default: null },
    isCorrect: { type: Boolean, default: false },
  },
  watch: {
    correct() { this.update(); },
  },
  data() {
    const { answerText, identifier, isCorrect } = this;

    return {
      id: identifier,
      answer: answerText,
      correct: isCorrect,
    };
  },
  computed: {
    formData() {
      const { answer, correct } = this;
      return { answer, correct };
    },
  },
  methods: {
    create() {
      this.hide();
      const endpoint = '/api/v1/group-admin/answer';
      const { formData } = this;
      formData.question_id = this.$parent.$parent.id;
      axios.post(endpoint, formData).then((res) => {
        const { data } = res;
        const { resource } = data;
        this.id = resource.id;
        this.$parent.$emit('answer:added', resource);
      });
    },
    handleRemove() {
      const confirmed = confirm('Are you sure you want to delete?');
      if (confirmed) {
        this.remove();
      }
    },
    remove() {
      const endpoint = `/api/v1/group-admin/answer/${this.id}/delete`;
      axios.post(endpoint).then((res) => {
        const { data } = res;
        const a = data.resource;
        this.$parent.$emit('answer:deleted', a);
      });
    },
    update() {
      if (this.id != null) {
        const endpoint = `/api/v1/group-admin/answer/${this.id}/update`;
        axios.post(endpoint, this.formData).then((res) => {
          this.hide();
        });
      }
    },
    delayedUpdate() {
      if (this.id != null) {
        requestIdleCallback(this.update, { timeout: 5000 });
      }
    },
  },
};
