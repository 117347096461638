<template>
  <span class="questionSvg">
    <svg
      width="41px"
      height="41px"
      viewBox="0 0 41 41"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 56.2 (81672) - https://sketch.com -->
      <title>Question</title>
      <desc>Created with Sketch.</desc>
      <g
        id="1.0---Pre---Enrolment---Desktop---Invalid-Card-Journey"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="1.1---CSCS-Card-Number-Entry"
          transform="translate(-188.000000, -830.000000)"
        >
          <g
            id="Group-5"
            transform="translate(0.000000, 774.000000)"
          >
            <g
              id="Group-13"
              transform="translate(184.000000, 52.000000)"
            >
              <rect
                id="Rectangle"
                x="-2.84217094e-14"
                y="-2.84217094e-14"
                width="48.7058824"
                height="48.7058824"
              />
              <path
                id="Shape"
                d="M24.3529412,7.10294118 C14.8251209,7.10294118 7.10294118,14.8244732 7.10294118,24.3529412 C7.10294118,33.8807615 14.8244732,41.6029412 24.3529412,41.6029412 C33.8807615,41.6029412 41.6029412,33.8814091 41.6029412,24.3529412 C41.6029412,14.8251209 33.8814091,7.10294118 24.3529412,7.10294118 Z M24.3529412,4.05882353 C35.5635372,4.05882353 44.6470588,13.1448095 44.6470588,24.3529412 C44.6470588,35.5635372 35.5610729,44.6470588 24.3529412,44.6470588 C13.1423451,44.6470588 4.05882353,35.5610729 4.05882353,24.3529412 C4.05882353,13.1423451 13.1448095,4.05882353 24.3529412,4.05882353 Z"
                fill="#FFFFFF"
                fill-rule="nonzero"
              />
              <path
                id="Shape"
                d="M25.3676471,28.4117647 C25.3676471,29.2523747 24.6861982,29.9338235 23.8455882,29.9338235 C23.0049783,29.9338235 22.3235294,29.2523747 22.3235294,28.4117647 L22.3235294,27.6426688 C22.3235294,25.4427793 23.3246764,24.3021495 25.5339084,23.0152558 C25.4781625,23.0477282 26.1795134,22.6427528 26.3633096,22.5330524 C28.2273533,21.4204799 28.9191176,20.4546342 28.9191176,18.2203999 C28.9191176,15.7063688 27.1692927,14.0890974 23.8455882,14.0890974 C20.8067552,14.0890974 18.7720588,16.4417049 18.7720588,19.1626268 C18.7720588,20.0032367 18.0906099,20.6846857 17.25,20.6846857 C16.4093901,20.6846857 15.7279412,20.0032367 15.7279412,19.1626268 C15.7279412,14.8507326 19.0194173,11.0449798 23.8455882,11.0449798 C28.7992884,11.0449798 31.9632353,13.9692498 31.9632353,18.2203999 C31.9632353,21.6678871 30.6272599,23.5331842 27.9234552,25.1469761 C27.7174001,25.2699621 26.9925094,25.6885299 27.0661295,25.6456456 C25.6999754,26.4414403 25.3676471,26.8200697 25.3676471,27.6426688 L25.3676471,28.4117647 Z M23.7441172,36.5294118 C22.6233042,36.5294118 21.7147055,35.6208131 21.7147055,34.5 C21.7147055,33.3791869 22.6233042,32.4705882 23.7441172,32.4705882 C24.8649305,32.4705882 25.773529,33.3791869 25.773529,34.5 C25.773529,35.6208131 24.8649305,36.5294118 23.7441172,36.5294118 Z"
                fill="#FFFFFF"
                fill-rule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'QuestionSvg',
};
</script>

<style>
  .questionSvg svg {
    vertical-align: text-bottom;
  }
</style>
