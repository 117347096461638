<template>
  <fieldset class="form-groups verify-user">
    <div
      v-if="operativeAge < 16"
      class="alert alert-warning"
    >
      STUDENT, work experience duties only
    </div>
    <div
      v-else-if="operativeAge < 18"
      class="alert alert-warning"
    >
      Young Person, must only work under supervision
    </div>
    <div
      v-if="expiredCert > 0"
      class="alert alert-warning"
    >
      This operative has an expired certification.
    </div>
    <div
      v-if="!redCard"
      class="urn-profile"
    >
      <div
        v-if="!generalVerificationCheck"
        class="urn-profile verify-user__warnings"
      >
        <div class="verify-user__warnings--header">
          Enrolment Invalid
        </div>
        <div class="verify-user__warnings--body">
          <p>This operative cannot be verified for the following reasons:</p>
          <ul>
            <li v-if="!isCitbValid() && uProfile.personalInformation.card_type === 'cert'">
              Pass certificate is expired. <button
                type="button"
                class="btn btn-secondary"
                data-container="body"
                data-toggle="tooltip"
                data-placement="top"
                title="Please review the operatives information and ensure its validity">?</button>
            </li>
            <li v-else-if="!isCitbValid()">
              Card is expired. <button
                type="button"
                class="btn btn-secondary"
                data-container="body"
                data-toggle="tooltip"
                data-placement="top"
                title="Please review the operatives information and ensure its validity">?</button>
            </li>
            <li v-if="!checkedMembership">
              Their membership hasn't been checked. <button
                type="button"
                class="btn btn-secondary"
                data-container="body"
                data-toggle="tooltip"
                data-placement="top"
                title="Please check the validation check box is ticked">?</button>
            </li>
            <li v-if="redCard">
              They have a red card. <button
                type="button"
                class="btn btn-secondary"
                data-container="body"
                data-toggle="tooltip"
                data-placement="top"
                title="Please contact the help desk for further information">?</button>
            </li>
            <li v-if="!picture">
              No ID picture found. <button
                type="button"
                class="btn btn-secondary"
                data-container="body"
                data-toggle="tooltip"
                data-placement="top"
                title="Please contact the help desk for further information">?</button>
            </li>
          </ul>
        </div>
      </div>
      <br>

      <div class="urn-profile__item verify-user">
        <div class="verify-user__header">
          <p>You’re currently verifying inductees for:</p>
        </div>
        <div class="verify-user__body form-group form-group__select">
          <u-admin-project-selector
            ref="projectSelector"
            :u-projects="uProjects"
            @input="
              project => {
                projectUpdated(project);
              }
            "
          />
        </div>
        <div>
          <div class="form-group d-inline">
            <div class="field field--checkbox has-danger">
              <label class="form-check-label">
                <input
                  id="gatekeeper"
                  type="checkbox"
                  form="verify"
                  name="gatekeeper"
                  value="1"
                  class="form-check-input"
                  @click="gatekeeper = !gatekeeper">
                <span class="font-weight-normal">Is the operative a gatekeeper for this site?</span>
              </label>
            </div>
            <div class="field field--checkbox has-danger">
              <label class="form-check-label">
                <input
                  id="new_job"
                  type="checkbox"
                  form="verify"
                  name="new_job"
                  value="1"
                  class="form-check-input"
                  @click="newJob = !newJob">
                <span class="font-weight-normal">Is this a new job for the operative?</span>
              </label>
            </div>
            <div class="field field--checkbox has-danger">
              <label class="form-check-label">
                <input
                  id="alternate_postcode"
                  type="checkbox"
                  class="form-check-input"
                  @click="alternatePostcodeUsed = !alternatePostcodeUsed">
                <span class="font-weight-normal">Is the operative using an alternative / temporary postcode to travel to site?</span>
              </label>
            </div>
          </div>
          <div
            v-if="alternatePostcodeUsed"
            class="form-group">
            <div class="field field--text has-danger">
              <label class="field__label">Alternative / temporary postcode</label>
              <div class="field__input"><input
                v-model="alternatePostcode"
                form="verify"
                name="alternate_postcode"
                type="text"
                placeholder=""
                value=""
                autocomplete="off"
                class="input"
                @blur="validateAlternatePostcode = true"></div>
              <span
                v-if="validateAlternatePostcode && !hasValidAlternatePostcode"
                class="field__feedback error">A valid postcode is required when alternative option is selected.</span>
            </div>
          </div>
        </div>

        <div class="form-group form-group__buttons">
          <input
            :value="project.id"
            form="verify"
            type="hidden"
            name="project"
          >
          <input
            :value="userMsiteId"
            form="verify"
            type="hidden"
            name="msite_id"
          >
          <input
            :class="verifyClass"
            :value="`Verify ${uName}`"
            :disabled="verifyDisable"
            form="verify"
            class="button button--block button--center success"
            type="submit"
            @click="submit"
          >
        </div>
      </div>
    </div>

    <slot />

    <div
      v-show="msiteModal"
      class="project-list__modal pre-lightbox"
    >
      <div class="pre-lightbox__content project-list__lightbox--content">
        <strong style="margin-bottom: 0.5em;">You are about to verify this user to an MSite project but this operative is not associated to any MSite profile</strong>
        <small class="">Please enter their MSite ID in the box below or leave blank if the operative does not have a profile on MSite.</small>
        <div
          class=""
          style="margin-bottom: 1em; margin-top: 1em">
          <div class="">
            <label
              class="field__label"
              style="margin-bottom: 0px;">Msite ID</label>
            <div class="field__input">
              <input
                v-model="userMsiteId"
                type="text"
                class="input" >
            </div>
          </div>
        </div>
        <small>You can find the operatives MSite ID at the end of the address in the browser</small>
        <small>https://willmottdixon.msitecloud.com/Employee/<strong>12345</strong></small>

        <div
          class="pre-lightbox__footer"
          style="margin-top: 1em">
          <button
            class="button outline"
            @click.prevent="msiteModal = false"
          >
            Cancel
          </button>
          <button
            class="button success"
            @click="submit"
          >
            Verify user to project
          </button>
        </div>
      </div>
    </div>

  </fieldset>
</template>

<script>
import axios from 'axios';
import { eventBus } from '../../app';

export default {
  props: {
    uLcos: {
      type: Array,
      default() {
        return [];
      },
    },
    uName: {
      type: String,
      default: 'Operative',
    },
    uMembership: {
      type: Boolean,
      default: false,
    },
    uProjects: {
      type: Array,
      default() {
        return [];
      },
    },
    uProfile: {
      type: Object,
      default() {
        return [];
      },
    },
    /**
         * Specifify if user has red card
         */
    redCard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      project: {},
      originalProjects: [],
      dateTimeNow: Date.now(),
      checkedMembership: this.uMembership,
      verifyClass: '',
      verifyDisable: true,
      picture: false,
      expiredCert: 0,
      gatekeeper: false,
      newJob: false,
      alternatePostcodeUsed: false,
      alternatePostcode: '',
      validateAlternatePostcode: false,
      msiteModal: false,
      userMsiteId: null,
    };
  },
  computed: {
    hasValidAlternatePostcode() {
      if (!this.alternatePostcodeUsed) {
        return true;
      }

      const validPostcode = /^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/i.test(this.alternatePostcode);
      return validPostcode;
    },
    operativeAge() {
      const today = new Date();
      const birthDate = new Date(
        this.uProfile.personalInformation.date_of_birth,
      );
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    generalVerificationCheck() {
      return (
        this.isCitbValid()
                && this.checkedMembership
                && !this.redCard
                && this.picture
      );
    },
  },
  watch: {
    checkedMembership() {
      this.canBeVerified();
    },
    project() {
      this.canBeVerified();
    },
  },
  mounted() {
    const componentEl = this;
    eventBus.$on('idphoto-change', (data) => {
      componentEl.picture = data.picture;
      componentEl.canBeVerified();
    });
    eventBus.$on('certs-updated', (data) => {
      this.checkFileExpiry(data.uploaded);
    });
  },
  created() {
    this.originalProjects.push(...this.uProjects);
    this.canBeVerified();
  },
  methods: {
    submit(e) {
      if (!this.hasValidAlternatePostcode) {
        e.preventDefault();
      }

      if (!this.uProfile.user.msite_turnstile_reference && !this.userMsiteId && !this.msiteModal) {
        const component = this;
        e.preventDefault();
        axios.get(`/api/v1/admin/project/${this.project.id}`)
          .then((response) => {
            if (response.data.turnstileProvider == 'MSITE') {
              component.msiteModal = true;
            } else {
              document.getElementById('verify').submit();
            }
          });
      }
    },
    projectUpdated(project) {
      if (project) this.project = project;
      else this.project = {};
    },
    lcoUpdated(lco) {
      while (this.uProjects.length > 0) {
        this.uProjects.pop();
      }

      if (lco !== null) {
        this.uProjects.push(...this.sortProjects(lco.projects));
      } else {
        this.uProjects.push(...this.originalProjects);
      }
      this.$refs.projectSelector.projects = this.uProjects;
      this.$refs.projectSelector.project = null;
      this.projectUpdated(null);
    },
    isCitbValid() {
      const reducedExpiry = this.uProfile.qualifications.expiry_date.split(
        'T',
      );
      const newExpiry = reducedExpiry[0].split('-');
      const date = new Date();
      // Hours part from the timestamp
      const day = date.getDate();
      const monthIndex = date.getMonth() + 1;
      const year = date.getFullYear();

      let expiryDate;
      let newDate;

      if (this.uProfile.personalInformation.card_type === 'citb') {
        newDate = new Date(`${year}/${monthIndex}/01 00:00:00`);
        expiryDate = new Date(
          `${newExpiry[0]}/${newExpiry[1]}/31 00:00:00`,
        );
      } else {
        newDate = new Date(`${year}/${monthIndex}/${day} 00:00:00`);
        expiryDate = new Date(
          `${newExpiry[0]}/${newExpiry[1]}/${newExpiry[2]} 00:00:00`,
        );
        if (this.uProfile.personalInformation.card_type === 'cert') {
          expiryDate.setMonth(expiryDate.getMonth() + 3);
        }
      }

      return expiryDate > newDate;
    },
    canBeVerified() {
      const result = this.generalVerificationCheck
                && Object.keys(this.project).length !== 0;

      if (result == false) {
        this.verifyClass = 'button--faded';
        this.verifyDisable = true;
      } else {
        this.verifyClass = '';
        this.verifyDisable = false;
      }
    },
    sortProjects(projectList) {
      return projectList.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
    },
    checkFileExpiry(files) {
      this.expiredCert = 0;
      files.forEach((certs) => {
        certs.files.forEach((file) => {
          if (file.expiry_date === 'Expired') this.expiredCert++;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.verify-user {
    &__warnings {
        text-align: left;
        border-radius: 1rem;
        border: 1px solid red;
        background-color: white;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        &--header {
            padding: 1rem;
            background-color: red;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            color: white;
            font-weight: 600;
        }
        &--body {
            padding: 1rem;
        }
    }
}
</style>
