<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h4>Create a new {{ reportType }} report</h4>
        <p class="export-report__lead">Select a date range using the date pickers below and click Generate Report</p>
        <download-csv
          :resource="resource"
          @report-created="addNewExportedReport"/>
      </div>
    </div>
    <div
      v-if="reports.length > 0"
      class="row export-report__result-wrapper">
      <div class="col-sm-9 export-report__result">
        <h5>Previously created {{ reportType }} reports</h5>
        <div class="export-report export-report__header row">
          <div class="col-3">Date range</div>
          <div class="col-2">Date created</div>
          <div class="col-3">Expiry date</div>
        </div>
        <div
          v-for="report in reports"
          :key="report.id"
          class="export-report row"
        >
          <div class="col-3">{{ formatDate(report.from_date) }} - {{ formatDate(report.to_date) }}</div>
          <div class="col-2">{{ formatDate(report.created_at) }}</div>
          <div class="col-3">{{ generateExpiryDate(report.created_at) }} ({{ generateFromNowExpiryDate(report.created_at) }})</div>
          <a
            v-if="report.produced"
            :href="`/admin/reporting/download/${report.uid}`"
            class="col-4 button success export-report__button"
          >Download Report</a>
          <button
            v-else
            class="col-4 button export-report__button export-report__button--disabled"
            @click.prevent="return false;">Generating Report</button>
        </div>
      </div>
    </div>
    <div
      v-show="reportRequested"
      class="pre-lightbox"
    >
      <div class="pre-lightbox__content">

        <div>
          <img
            src="/assets/static/icon_creating-report-csv.png"
            alt="creating csv report">
          <p class="lead">
            <strong>Your report is being generated</strong>
          </p>
        </div>
        <p>It can take up to 10 minutes to create this report, we will send you an email when it has completed. You can also check back on this page and check you <strong>Previously created {{ reportType }} reports</strong> list.</p>
        <button
          class="button success pre-lightbox__button"
          @click="reportRequested = false"
        >
          Okay
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import DownloadCsv from '../admin/DownloadCsv.vue';

export default {
  name: 'CsvExportTab',
  components: {
    DownloadCsv,
  },
  props: {
    resource: {
      type: String,
      default: '',
    },
    reports: {
      type: Array,
      default() {
        return [];
      },
    },
    reportType: {
      type: String,
      default: 'exported',
    },
  },
  data() {
    return {
      expiryLimit: 0,
      reportRequested: false,
    };
  },
  mounted() {
    this.expiryLimit = window.systemReportExpiry;
  },
  methods: {
    addNewExportedReport(reportDetails) {
      this.reportRequested = true;
      this.reports.unshift({ ...reportDetails, produced: false });
    },
    formatDate(date, format = 'D MMM YYYY') {
      return moment(date).format(
        format,
      );
    },
    generateExpiryDate(date) {
      return this.formatDate(moment(date).add(this.expiryLimit, 'days'));
    },
    generateFromNowExpiryDate(date) {
      const expiryDate = moment(date).add(this.expiryLimit, 'days').endOf('day');
      const expiryEndOf = moment().endOf('day');
      const differenceInHours = expiryDate.diff(expiryEndOf, 'hours');

      if (differenceInHours > 24) {
        return moment(date).add(this.expiryLimit, 'days').fromNow(true);
      }

      return expiryDate.fromNow(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";
@import "./../../../styles/variables.scss";
.export-report {
  align-items: center;
  background-color: #FDFDFD;
  border-radius: 0.3rem;
  border: 1px solid lightgray;
  font-size: 0.85rem;
  margin-bottom: 1rem;

  &__lead {
    margin-bottom: 3rem;
  }

  &__header {
    font-weight: bold;
    font-size: 0.9rem;
    border: none;
    margin-bottom: 1rem;
    margin-top: 1rem;
    background-color: transparent;
  }

  &__result-wrapper {
    overflow-x: scroll;
  }

  &__result {
    min-width: 500px;
  }

  &__button {
    margin-bottom: 0;

    &--disabled {
      background-color: #979797;
      color: #fff;
    }
  }

}

.pre-lightbox {
    &__button {
      padding: 1rem 5rem;
    }
}
</style>
