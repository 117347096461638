<template>
  <div>
    <div
      :class="albumClass"
      class="my-gallery"
    >
      <a
        v-for="(image, key) in images"
        :key="key"
        :href="image.src"
      >
        <img
          :src="image.src"
          :title="image.title"
          :class="imageClass"
          alt=""
        >
        <slot />
      </a>
    </div>
  </div>
</template>

<script>
import cookieMixn from '../../mixins/Cookie';

export default {
  mixins: [cookieMixn],
  props: {
    id: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
    imageClass: {
      type: String,
      default: '',
    },
    albumClass: {
      type: String,
      default: '',
    },
    options: {
      type: Object,
      required: false,
      default() {
        return {};
      },
    },
    openOnLoad: {
      type: String,
      required: false,
      default: '',
    },
  },
  mounted() {
    window.$ = window.jQuery = require('jquery');
    const simplelightbox = require('simplelightbox');
    if (this.options != undefined) {
      const gal = $('.my-gallery a').simpleLightbox(this.options);
      if (this.openOnLoad !== '') {
        this.setCookie(`${this.openOnLoad}-lightbox`, 'shown', 7);
        gal.open();
      }
    } else {
      const gal = $('.my-gallery a').simpleLightbox();
      if (this.openOnLoad !== '') {
        this.setCookie(`${this.openOnLoad}-lightbox`, 'shown', 7);
        gal.open();
      }
    }
  },
};
</script>

<style>
.my-gallery a img {
    float: left;
    width: 20%;
    height: auto;
    border: 2px solid #fff;
    -webkit-transition: -webkit-transform 0.15s ease;
    -moz-transition: -moz-transform 0.15s ease;
    -o-transition: -o-transform 0.15s ease;
    -ms-transition: -ms-transform 0.15s ease;
    transition: transform 0.15s ease;
    position: relative;
}
.my-gallery a:hover img {
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -o-transform: scale(1.05);
    -ms-transform: scale(1.05);
    transform: scale(1.05);
    z-index: 5;
}
.my-gallery a.big img {
    width: 40%;
}
.align-center {
    text-align: center;
}
@import url("https://fonts.googleapis.com/css?family=Ruda");
@import "~simplelightbox/dist/simplelightbox.css";
</style>
