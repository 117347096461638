<template>
  <div>
    <fieldset class="row">
      <div class="col">
        <div class="form-group">
          <u-gosmart-card-selector-field
            :u-go-smart-cards="uGoSmartCards"
            :u-temp-user="tempUser"
            :u-pass-cert="passCert"
            @input="select" />
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
export default {
  props: {
    uRoute: {
      type: String,
      default: '/',
    },
    uGoSmartCards: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      goSmartCheckFailed: false,
      errors: {},
      cardNumber: '',
      surname: '',
    };
  },
  computed: {
    clientConfigName() {
      return window.clientConfig['client-name'];
    },
  },
  methods: {
    select(goSmartCard) {
      if (!goSmartCard) return;

      if (goSmartCard.manual === true) {
        window.location.href = `${this.uRoute}?type=noncscs`;
      } else {
        window.location.pathname += `/${goSmartCard.abbreviation}`;
      }
    },
    tempUser() {
      window.location.href = `${this.uRoute}?type=temp`;
    },
    passCert() {
      window.location.href = `${this.uRoute}?type=cert`;
    },
  },
};
</script>
