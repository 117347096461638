<script>
import axios from "axios";

import {TickIcon} from "@tribus/common-icons";

export default {
    name: "MsiteUserRowLinkModal",
    components: {
        TickIcon,
    },
    props: {
      user: {type: Object, required: true},
      userId: {type: [String, Number], required: true},
        linkData: {type: Object, default: null},
        msiteNumber: {type: Number, required: true},
    },
    data(){
      return {
          isLoading: false,
          isError: false,
      }
    },
    created() {
        this.getMsiteData();
    },
    emits: ['cancel', 'confirm'],
    computed: {
      isNameMatch(){
          if(this.linkData){
              return this.linkData.msiteUserDetails.name.toLowerCase() === (this.user.first_name.toLowerCase() + ' ' + this.user.last_name.toLowerCase());
          }
          return false;
      },
        isEmailMatch(){
            if(this.linkData && this.linkData.msiteUserDetails.email){
                return this.linkData.msiteUserDetails.email.toLowerCase() === this.linkData.user.email.toLowerCase();
            }
            return false;
        }
    },
    methods: {
        async getMsiteData(){
            this.isError = false;
            this.isLoading = true;
            try{
                const {data} = await axios.get(`/group-admin/msite-verification/get-msite-user-data/${this.userId}/${this.msiteNumber}`);
                this.linkData = data;
            } catch (e) {
                this.isError = true;
            }
            finally{
                this.isLoading = false;
            }
        },
        confirm(){
            this.$emit('confirm');
        },
        cancel(){
            this.$emit('cancel');
        }

    }
}
</script>

<template>
    <div
        class="project-list__modal pre-lightbox"
    >
        <div class="pre-lightbox__content project-list__lightbox--content">
            <div v-if="isLoading" class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div v-else-if="isError">
                <p>Unable to find a user with the provided MSite ID.</p>
                <button
                    class="button outline"
                    @click="cancel()"
                >
                    OK
                </button>
            </div>
            <div v-else>
                <p>
                    Are you sure you want to link the Msite ID <span class="font-weight-bold">{{msiteNumber}}</span> to <span class="font-weight-bold">{{user.first_name}} {{user.last_name}}</span>?
                </p>
                <div class="my-5 text-left">
                    <h5 class="mb-3 font-weight-bold">Msite Data</h5>
                    <ul class="list-group">
                        <li class="list-group-item d-flex align-items-center">
                            <div class="w-50 font-weight-bold text-left d-flex align-items-center justify-content-between pr-2">Name<svg v-if="isNameMatch" role="img" title="Match" class="icon-tick" width="25px" height="25px"><use xlink:href="/build/sprite/sprite.svg#sprite-tick"></use></svg></div>
                            <div class="w-50 text-left">{{linkData.msiteUserDetails.name}}</div>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                            <div class="w-50 font-weight-bold text-left  d-flex align-items-center justify-content-between pr-2">Email<svg v-if="isEmailMatch" role="img" title="Match" class="icon-tick" width="25px" height="25px"><use xlink:href="/build/sprite/sprite.svg#sprite-tick"></use></svg></div>
                            <div class="w-50 text-left">{{linkData.msiteUserDetails.email}}</div>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                            <div class="w-50 font-weight-bold text-left  d-flex align-items-center justify-content-between pr-2">Employer</div>
                            <div class="w-50 text-left">{{linkData.msiteUserDetails.employer}}</div>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                            <div class="w-50 font-weight-bold text-left  d-flex align-items-center justify-content-between pr-2">NI Number</div>
                            <div class="w-50 text-left">{{linkData.msiteUserDetails.niNumber}}</div>
                        </li>
                        <li class="list-group-item d-flex align-items-center">
                            <div class="w-50 font-weight-bold text-left  d-flex align-items-center justify-content-between pr-2">Postcode</div>
                            <div class="w-50 text-left">{{linkData.msiteUserDetails.postcode}}</div>
                        </li>
                    </ul>
                </div>

                <div class="pre-lightbox__footer">
                    <button
                        class="button outline"
                        @click="cancel()"
                    >
                        No, Cancel
                    </button>
                    <button
                        class="button success"
                        @click="confirm()"
                    >
                        Yes, Link Msite ID
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<style scoped lang="scss">

</style>
