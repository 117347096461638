<template>
  <span class="crossSvg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="512"
      height="512"
      viewBox="0 0 339.177 339.177"
    >
      <path
        :fill="crossColor"
        d="M247.244 169.59l83.938-83.938c5.332-5.327 7.994-11.798 7.994-19.414 0-7.614-2.67-14.084-7.994-19.414l-38.827-38.83c-5.33-5.33-11.8-7.994-19.41-7.994-7.618 0-14.086 2.665-19.418 7.993L169.59 91.93 85.65 7.994C80.326 2.665 73.855 0 66.238 0c-7.61 0-14.083 2.665-19.414 7.993l-38.83 38.83C2.668 52.15 0 58.624 0 66.24c0 7.616 2.664 14.084 7.994 19.414L91.93 169.59 7.995 253.528C2.667 258.858 0 265.328 0 272.945c0 7.61 2.664 14.082 7.994 19.41l38.83 38.828c5.33 5.332 11.803 7.994 19.414 7.994 7.616 0 14.084-2.67 19.414-7.994l83.94-83.938 83.943 83.938c5.328 5.332 11.793 7.994 19.417 7.994 7.61 0 14.082-2.67 19.41-7.994l38.82-38.828c5.333-5.324 7.995-11.8 7.995-19.41 0-7.618-2.662-14.086-7.994-19.417l-83.94-83.938z"
      />
    </svg>
  </span>
</template>

<script>
export default {
  name: 'CrossSvg',
  props: {
    crossColor: {
      default: '#ff5b77',
      type: String,
    },
  },
  mounted() {},
};
</script>

<style>
.crossSvg svg {
    vertical-align: text-bottom;
    width: 1rem;
    height: 1rem;
}
</style>
