<script>
import { VueEditor } from "vue2-editor";
export default {
    name: "ConditionalSection",
    components: { VueEditor },
    props: {
        name: { type: String, default: null },
        value: { type: String, default: '' },
        form: { type: String, default: null },
    },
    data() {
        return {
            content: this.value,
            customToolbar: [
                ["bold", "italic", "underline"],
                ["link"]
            ]
        };
    },
    created() {
        this.content = this.value;
    },
    watch:{
        value(val) {
            this.content = val;
        }
    },
    computed: {
        editorContent: {
            get: function () {
                return this.content;
            },
            // setter
            set: function (newValue) {
                this.content = newValue
                this.$emit('input', newValue);
            }
        }
    }
}
</script>

<template>
<div>
    <slot name="label"></slot>
    <div class="bg-white">
        <vue-editor v-model="editorContent" :editor-toolbar="customToolbar" />
    </div>
    <input v-if="name" type="hidden" :form="form" :name="name" :value="content"/>
    <slot name="errors"></slot>
</div>
</template>

<style scoped lang="scss">

</style>
