import { Fact, Facts } from '@tribus/facts';
import Profile from '@tribus/profile';
import Result from '../components/admin/search/results/Result';
import Results from '../components/admin/search/results/Results';
import Vue from 'vue';
import Cards from '../components/admin/Cards.vue';
import { CardIcon } from '@tribus/common-icons';

window.addEventListener('DOMContentLoaded', () => {
  const searchEl = '[data-vm="search"]';
  if (document.querySelector(searchEl)) {
    new Vue({
      el: searchEl,
      components: {
        Fact,
        Facts,
        Profile,
        Result,
        Results,
        Cards,
        CardIcon
      },
    });
  }
});
