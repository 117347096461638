<template>
  <div
    :class="{'has-danger' : $root.hasErrors(form, inputKey) || (errors.length > 0 && $root.isFreshError(form, inputKey)), containerClass}"
    class="field field--text"
  >
    <label
      v-if="label !== ''"
      class="field__label"
    >{{ label }}</label>

    <div class="field__input">
      <input
        :v-model="vueControl"
        :form="form"
        :name="inputKey"
        :class="inputKeyClass"
        :placeholder="placeholder"
        :maxlength="max"
        :disabled="disabled"
        :readonly="readonly"
        :v-validate="validate"
        :value="inputValue"
        type="text"
        autocomplete="off"
      >
    </div>

    <slot />

    <span
      v-for="(error, errorKey) in $root.getErrors(form, inputKey)"
      v-cloak
      :key="errorKey"
      class="field__feedback error"
    >@{{ error }}</span>

    <template v-for="(error, errorIndex) in errors">
      <span
        v-if="$root.isFreshError(form, inputKey)"
        :key="errorIndex"
        class="field__feedback error"
      >{{ error }}</span>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    vueControl: {
      type: String,
      default: '',
    },
    form: {
      type: String,
      default: '',
    },
    inputKey: {
      type: String,
      default: '',
    },
    inputClass: {
      type: String,
      default: '',
    },
    inputValue: {
      type: String,
      default: '',
    },
    fieldClass: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    max: {
      type: Number,
      default: 255,
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    validate: {
      type: String,
      default: '',
    },
  },
  computed: {
    containerClass() {
      let fieldClass = '';

      if (this.errors.length > 0) {
        fieldClass += ' has-danger';
      }

      if (this.fieldClass !== '') {
        fieldClass += ` ${this.fieldClass}`;
      }

      return fieldClass;
    },
    inputKeyClass() {
      let fieldClass = 'input';
      if (this.inputKey !== '') {
        fieldClass += ` text-${this.inputKey}`;
      }

      if (this.inputClass !== '') {
        fieldClass += ` ${this.inputClass}`;
      }

      return fieldClass;
    },
  },
};
</script>
