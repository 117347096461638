import Vue from 'vue';
import { CardIcon } from '@tribus/common-icons';
import Datepicker from 'vuejs-datepicker';
import Cards from '../components/admin/Cards.vue';
import Flags from '../components/admin/Flags.vue';
import CertificateUpload from '../components/ui/CertificateUpload.vue';
import errorsMixin from '../mixins/Errors';
import WebCamCapture from '../components/fields/WebCamCapture.vue';

window.addEventListener('DOMContentLoaded', () => {
  /**
     * Profile
     */
  const profileEl = '[data-vm="profile-identity"]';
  if (document.querySelector(profileEl)) {
    new Vue({
      el: profileEl,
      components: {
        /**
                 * Cards (green, orange, red)
                 */
        Cards,
        CardIcon, // Common icons
        Flags,
        WebCamCapture,
      },
      data() {
        return {
          msg: '',
          cardsIssued: null,
        };
      },
      watch: {
        cardsIssued(cardsIssued) {
          if (cardsIssued != null) {
            this.$refs.flags.resetFlag();
          }
        },
        msg(msg) {
          if (msg != null) {
            this.$refs.cards.resetCard();
          }
        },
      },
      /* mounted() {
        this.$refs.cards.$on('msg', (cardsIssued) => {
          this.cardsIssued = cardsIssued;
        });
        this.$refs.flags.$on('msg', (msg) => {
          this.msg = msg;
        });
      }, */
    });
  }

  /**
     * Profile for cards tab
     */
  const cardsTab = '[data-vm="cards-tab"]';
  if (document.querySelector(cardsTab)) {
    new Vue({
      el: cardsTab,
      components: {
        /**
                 * Cards (green, orange, red)
                 */
        Cards,
        CardIcon, // Common icons
      },
      data() {
        return {
          msg: null,
          cardsIssued: null,
        };
      },
      watch: {
        cardsIssued(cardsIssued) {
          if (cardsIssued != null) {
            this.$refs.flags.resetFlag();
          }
        },
        msg(msg) {
          if (msg != null) {
            this.$refs.cards.resetCard();
          }
        },
      },
      /* mounted() {
              this.$refs.cards.$on('msg', (cardsIssued) => {
                this.cardsIssued = cardsIssued;
              });
              this.$refs.flags.$on('msg', (msg) => {
                this.msg = msg;
              });
            }, */
    });
  }

  /**
     * Certificates
     */
  const certificateEl = '[data-vm="certificates"]';
  if (document.querySelector(certificateEl)) {
    new Vue({
      el: certificateEl,
      components: {
        Datepicker,
        CertificateUpload,
      },
      mixins: [errorsMixin],
      data() {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        return {
          disabledDates: {
            to: yesterday,
          },
        };
      },
    });
  }
});
