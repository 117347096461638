var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-cards"},[_c('div',{staticClass:"cards__card",class:{
      'cards__card--clickable': (_vm.cards.green.length >= 1),
      'cards__card--active': (_vm.card == 'green')
    },on:{"click":function($event){return _vm.setCard('green')}}},[_c('card-icon',{staticClass:"card-icon--warning"})],1),_vm._v(" "),_c('span',{staticClass:"card__count"},[_vm._v("\n    "+_vm._s(_vm.cards.green.length)+"\n  ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"cards__card",class:{
      'cards__card--clickable': (_vm.cards.yellow.length >= 1),
      'cards__card--active': (_vm.card == 'yellow')
    },on:{"click":function($event){return _vm.setCard('yellow')}}},[_c('card-icon',{staticClass:"card-icon--severe"})],1),_vm._v(" "),_c('span',{staticClass:"card__count"},[_vm._v("\n    "+_vm._s(_vm.cards.yellow.length)+"\n  ")]),_vm._v(" "),_c('br'),_vm._v(" "),_c('div',{staticClass:"cards__card",class:{
      'cards__card--clickable': (_vm.cards.red.length >= 1),
      'cards__card--active': (_vm.card == 'red')
    },on:{"click":function($event){return _vm.setCard('red')}}},[_c('card-icon',{staticClass:"card-icon--fatal"})],1),_vm._v(" "),_c('span',{staticClass:"card__count"},[_vm._v("\n    "+_vm._s(_vm.cards.red.length)+"\n  ")])])
}
var staticRenderFns = []

export { render, staticRenderFns }