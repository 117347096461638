import draggable from 'vuedraggable';
import PlaylistItem from './PlaylistItem';

/**
 * Playlist component
 *
 */
export default {
  name: 'playlist',
  components: {
    draggable,
    PlaylistItem,
  },
  props: {
    videos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      vids: this.videos,
    };
  },
  mounted() {
    this.$refs.sortable.$on('sort', this.syncVideoOrdering);
  },
  methods: {
    syncVideoOrdering() {
      const videos = this.vids.map(video => video.id);
      this.$emit('playlist:sync-ordering', videos);
    },
  },
};
