<template>
  <span class="magnifySvg">
    <svg
      id="Layer_1"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      style="enable-background:new 0 0 20 20;"
      xml:space="preserve"
    >
      <g>
        <path
          style="fill: transparent;"
          d="M12.63,4.2c-1.11-1.12-2.62-1.75-4.19-1.75l0,0c-1.58-0.01-3.1,0.61-4.23,1.72C3.09,5.28,2.46,6.79,2.45,8.37
       c-0.01,1.58,0.62,3.1,1.73,4.22c1.11,1.12,2.63,1.76,4.21,1.76c1.58,0,3.1-0.62,4.22-1.74c1.12-1.12,1.75-2.63,1.75-4.22
       C14.36,6.83,13.74,5.31,12.63,4.2z M8.26,11.26L4.45,7.03h7.63L8.26,11.26z"
        />
        <path
          id="outer"
          :style="'fill: '+ magnifyBorder"
          d="M20,18.18l-4.82-4.8c1.06-1.44,1.64-3.18,1.64-4.99c0-2.23-0.89-4.37-2.47-5.95
       c-1.58-1.58-3.72-2.46-5.95-2.46c-2.23,0-4.37,0.89-5.95,2.47C0.87,4.05-0.01,6.19,0,8.42c0.01,2.23,0.9,4.37,2.48,5.94
       c1.58,1.57,3.73,2.45,5.96,2.44v0c1.82-0.01,3.57-0.6,5.01-1.68l4.82,4.79L20,18.18z M8.39,14.35c-1.58,0-3.1-0.64-4.21-1.76
       c-1.11-1.12-1.74-2.64-1.73-4.22c0.01-1.58,0.64-3.1,1.77-4.21c1.12-1.11,2.65-1.73,4.23-1.72l0,0c1.57,0.01,3.08,0.64,4.19,1.75
       c1.11,1.12,1.73,2.63,1.73,4.2c0,1.58-0.63,3.1-1.75,4.22C11.49,13.73,9.97,14.36,8.39,14.35z"
        />
        <polygon
          id="inner"
          :style="'fill: '+ magnifyColor"
          points="8.26,11.26 12.08,7.03 4.45,7.03"
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'MagnifySvg',
  props: {
    magnifyColor: {
      default: 'transparent',
      type: String,
    },
    magnifyBorder: {
      default: '#9B9B9B',
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
  .magnifySvg svg {
    vertical-align: text-bottom;
  }
</style>
