import { ToggleButton } from 'vue-js-toggle-button';
import { HamburgerIcon } from '@tribus/common-icons';
import axios from 'axios';

const randomItem = require('random-item');

/**
 * Playlist item component
 *
 */
export default {
  name: 'playlist-item',
  components: {
    HamburgerIcon,
    ToggleButton,
  },
  props: {
    id: { type: Number, default: null },
    title: { type: String, default: 'Untitled' },
    url: { type: String, default: '#' },
    index: { type: Number, default: 0 },
    state: { type: Boolean, default: true },
    questions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      toggle: this.state,
      toggleColors: { checked: '#47D1B0', unchecked: '#D9D9D9' },
    };
  },
  mounted() {},
  computed: {
    number() {
      const number = this.index + 1;
      return number.toString().padStart(2, '0'); // Two digits, leading zero
    },
    totalQuestions() {
      return this.questions.length;
    },
  },
  watch: {
    toggle() {
      axios.get(`/api/v1/group-admin/videos/${this.id}/set-status`);
    },
  },
};
