<template>
  <div
    :class="{ active: focused, collapsed: !focused }"
    class="project-selector"
    @mouseleave="focused = false"
  >
    <ul>
      <li
        v-if="lco == null || focused"
        class="project-selector__item empty"
        @click.prevent="selectPlaceholder()"
      >
        <h1 class="project__name">
          Select {{ clientConfigOffice }}
        </h1>
      </li>

      <li
        v-for="item in filteredLcos"
        :key="item.id"
        :class="{ selected: lco && lco.id === item.id }"
        class="project-selector__item"
        @click.prevent="select(item)"
      >
        <h1
          class="project__name"
          v-text="item.name"
        />
      </li>
    </ul>

    <span
      v-if="!focused"
      class="fas fa-chevron-down fa-fw"
    />
  </div>
</template>

<script>
export default {
  props: {
    uLcos: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      lco: null,
      focused: false,
    };
  },
  computed: {
    filteredLcos() {
      return this.uLcos.filter(lco => lco.projects.length > 0);
    },
    clientConfigOffice() {
      return window.clientConfig['client-office'];
    },
  },
  methods: {
    select(lco) {
      if (!this.focused) {
        this.focused = true;

        return;
      }

      this.lco = lco;
      this.focused = false;

      this.$emit('input', lco);
    },
    selectPlaceholder() {
      if (!this.focused) {
        this.focused = true;
        return;
      }

      this.lco = null;
      this.focused = false;

      this.$emit('input', null);
    },
  },
};
</script>
