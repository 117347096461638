<template>
  <div class="form-group">
    <u-checkbox
      v-for="checkbox in inputData"
      :key="checkbox.key"
      :input-value="checkbox.inputValue"
      :field-class="checkbox.fieldClass"
      :input-form="checkbox.inputForm"
      :input-checked="checkbox.inputChecked"
      :input-key="checkbox.inputKey"
      :input-label="checkbox.inputLabel"
      :errors="checkbox.errors"
    />
  </div>
</template>

<script>
export default {
  props: {
    inputData: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
