import { ToggleButton } from 'vue-js-toggle-button';

/**
 * Criteria component
 *
 */
export default {
  name: 'criteria',
  components: { ToggleButton },
  props: {
    matchable: {
      type: Object,
      default() { // Name => Label
        return {};
      },
    },
    matched: {
      type: Array,
      default() {
        return [];
      },
    },
    toggleColor: { type: String, default: '#fff' },
    // TODO: Abstract these to PO/MO file like Tribus modules
    headingText: { type: String, default: null },
    disableText: { type: String, default: 'Disabled?' },
  },
  data() {
    const criteria = {};
    this.fields().map((field) => {
      const criterion = field.name;
      criteria[criterion] = this.matched.includes(criterion);
    });
    return {
      criteria,
      disabled: this.matched.length == 0,
    };
  },
  watch: {
    disabled(disabled) {
      this.$emit('criteria-disabled', disabled);
      if (disabled) {
        Object.keys(this.criteria).forEach((option) => {
          this.criteria[option] = false;
        });
      }
    },
  },
  computed: {
    colors() {
      return {
        checked: this.toggleColor,
        unchecked: '#D9D9D9',
      };
    },
  },
  methods: {
    __(txt) {
      return txt;
    },
    fields() {
      return Object.entries(this.matchable).map(([name, label]) => ({ name, label }));
    },
  },
};
