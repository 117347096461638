import Form from '../app/Form';

export default {
  /**
     * Attach the event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  bind(el, binding, node) {
    let targets = binding.value || [];

    if (!Array.isArray(targets)) {
      targets = [targets];
    }

    const formId = el.getAttribute('form');

    binding.handler = _.debounce((e) => {
      const form = new Form();
      const formEl = document.querySelector(`form[id=${formId}]`);

      form.success((response) => {
        window.App.setErrors(formId, el.name, []);

        Object.keys(response.data.replace || {}).forEach((key) => {
          const targetEl = document.querySelector(
            `[name="${key}"][form="${formId}"]`,
          );

          if (targetEl === undefined) {
            return;
          }

          targetEl.value = response.data.replace[key];
        });
      });

      form.error((error) => {
        window.App.setErrors(formId, el.name, form.errors(el.name));
      });

      const data = {
        validate: {
          [el.name]: el.value,
        },
      };

      targets.forEach((target) => {
        const targetEl = document.querySelector(
          `[name="${target}"][form="${formId}"]`,
        );

        data.validate[target] = targetEl.value;
      });

      form.submit(formEl.action, 'POST', data);

      e.preventDefault();
    }, 100);

    el.addEventListener('blur', binding.handler, false);

    setTimeout(() => {
      targets.forEach((target) => {
        const targetEl = document.querySelector(
          `[name="${target}"][form="${formId}"]`,
        );

        targetEl.addEventListener('blur', binding.handler, false);
      });
    }, 1);
  },

  /**
     * Remove the un-needed event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  unbind(el, binding, node) {
    let targets = binding.value || [];

    if (!Array.isArray(targets)) {
      targets = [targets];
    }

    el.removeEventListener('blur', binding.handler, false);

    targets.forEach((target) => {
      el.form[target].removeEventListener('blur', binding.handler, false);
    });
  },
};
