<template>
  <span class="circleCrossSvg">
    <svg
      width="39px"
      height="39px"
      viewBox="0 0 39 39"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 56.2 (81672) - https://sketch.com -->
      <title>Circle Cross</title>
      <desc>Created with Sketch.</desc>
      <g
        id="1.0---Pre---Enrolment---Desktop---Invalid-Card-Journey"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="1.1---CSCS-Card-Number-Entry---Not-Found"
          transform="translate(-400.000000, -253.000000)"
          stroke="#50D7B9"
          stroke-width="2.65"
        >
          <g
            id="Group-2"
            transform="translate(402.000000, 255.000000)"
          >
            <circle
              id="Oval"
              fill="#FFFFFF"
              cx="17.5"
              cy="17.5"
              r="17.5"
            />
            <path
              id="Line"
              d="M24.1205732,11.0706662 L10.6912394,24.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              id="Line"
              d="M24.1205732,11.0706662 L10.6912394,24.5"
              stroke-linecap="round"
              stroke-linejoin="round"
              transform="translate(17.500000, 17.500000) rotate(-90.000000) translate(-17.500000, -17.500000) "
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'CircleCrossSvg',
};
</script>

<style>
  .circleCrossSvg.vue svg {
    vertical-align: text-bottom;
  }
</style>
