import Form from '../app/Form';

export default {
  /**
     * Attach the event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  bind(el, binding, node) {
    let stored = binding.value;

    binding.handler = (e) => {
      e.preventDefault();

      const input = el.parentElement.querySelector('input[type="file"]');

      if (
        stored
                && !confirm('Are you sure you wish to remove this file?')
      ) {
        return;
      }

      input.value = null;
      input.dispatchEvent(new InputEvent('change'));

      if (!stored) {
        return;
      }

      const append = document.createElement('input');

      append.type = 'hidden';
      append.name = 'delete[]';
      append.value = input.name;

      input.form.appendChild(append);

      stored = false;
    };

    el.addEventListener('click', binding.handler, false);
  },

  /**
     * Remove the un-needed event listener.
     *
     * @param  {ASTElement} el
     * @param  {Object}     binding
     * @param  {VNode}      node
     *
     * @return {Void}
     */
  unbind(el, binding, node) {
    el.removeEventListener('click', binding.handler, false);
  },
};
