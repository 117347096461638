<template>
  <input
    v-model="urn"
    class="input"
    type="text"
    placeholder="000-000"
  >
</template>

<script>
export default {
  props: {
    uValue: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      urn: this.uValue,
      removing: false,
    };
  },
  watch: {
    urn(value, old) {
      if (
        (value.length !== 4 && value.match(/\d$/) === null)
                || (value.length === 4 && value.match(/-$/) === null)
      ) {
        value = value.substr(0, value.length - 1);
        this.urn = value;
      }

      if (value.length > 7) {
        this.urn = value.substr(0, 7);

        return;
      }

      if (value.length !== 3 || value.match(/\d{3}/) === null) {
        return;
      }

      if (old.length === 4) {
        this.urn = value.substr(0, 2);

        return;
      }

      this.urn = `${value}-`;
    },
  },
};
</script>
