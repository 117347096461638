<template>
  <span class="tickSvg">
    <svg
      width="20px"
      height="16px"
      viewBox="0 0 20 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    >
      <!-- Generator: Sketch 46.2 (44496) - http://www.bohemiancoding.com/sketch -->
      <title>Tick</title>
      <desc>Created with Sketch.</desc>
      <defs />
      <g
        id="1.0---Willmott Dixon-Pre-Start---Desktop---Register"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="2.2---Register---Qualifications"
          :fill="tickColor"
          transform="translate(-861.000000, -1241.000000)"
        >
          <g
            id="Certs-Table"
            transform="translate(185.000000, 904.000000)"
          >
            <path
              id="Page-1"
              d="M683.688675,345.970201 L692.475909,337.182167 C692.718798,336.939278 693.120261,336.939278 693.36315,337.182167 L695.817833,339.63685 C696.060722,339.879739 696.060722,340.281202 695.817833,340.52721 C691.92297,344.422073 688.025508,348.317136 684.130646,352.214398 L684.108778,352.236267 L684.083785,352.258135 L684.069727,352.266727 L684.019742,352.299529 L684.005684,352.30812 L683.977568,352.319055 L683.966633,352.327647 L683.9104,352.349516 L683.896342,352.352639 L683.882282,352.358106 L683.783873,352.379975 L683.640946,352.379975 C683.626889,352.379975 683.610487,352.376852 683.596427,352.374509 L683.580026,352.371384 L683.537851,352.362013 L683.510515,352.353421 L683.494113,352.347955 L683.480055,352.34483 L683.465998,352.339363 C683.446471,352.330772 683.426947,352.322962 683.408202,352.312027 L683.394143,352.306559 L683.366807,352.290158 C683.327756,352.265165 683.289485,352.234706 683.255901,352.201901 C672.937114,341.885314 686.504153,355.446954 676.182167,345.128167 C675.939278,344.885278 675.939278,344.483815 676.182167,344.240926 L678.63685,341.786243 C678.879739,341.543353 679.281201,341.543353 679.52721,341.786243 L683.688395,345.971021 L683.688675,345.970201 Z"
            />
          </g>
        </g>
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: 'TickSvg',
  props: {
    tickColor: {
      default: '#50D7B9',
      type: String,
    },
  },
  mounted() {
  },
};
</script>

<style>
  .tickSvg svg {
    vertical-align: text-bottom;
  }
</style>
