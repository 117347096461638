<template>
  <fieldset class="form-groups invalid-enrolment">
    <div
      v-if="!generalVerificationCheck"
      class="urn-profile verify-user__warnings"
    >
      <div class="verify-user__warnings--header">
        Enrolment Invalid
      </div>
      <div class="verify-user__warnings--body">
        <p>This operative cannot be verified for the following reasons:</p>
        <ul>
          <li v-if="!isCitbValid() && uProfile.personalInformation.card_type === 'cert'">
            Pass certificate is expired. <button
              type="button"
              class="btn btn-secondary"
              data-container="body"
              data-toggle="tooltip"
              data-placement="top"
              title="Please review the operatives information and ensure its validity">?</button>
          </li>
          <li v-else-if="!isCitbValid()">
            Card is expired. <button
              type="button"
              class="btn btn-secondary"
              data-container="body"
              data-toggle="tooltip"
              data-placement="top"
              title="Please review the operatives information and ensure its validity">?</button>
          </li>
          <li v-if="!checkedMembership">
            Their membership hasn't been checked. <button
              type="button"
              class="btn btn-secondary"
              data-container="body"
              data-toggle="tooltip"
              data-placement="top"
              title="Please check the validation check box is ticked">?</button>
          </li>
          <li v-if="redCard">
            They have a red card. <button
              type="button"
              class="btn btn-secondary"
              data-container="body"
              data-toggle="tooltip"
              data-placement="top"
              title="Please contact the help desk for further information">?</button>
          </li>
          <li v-if="!picture">
            No ID picture found. <button
              type="button"
              class="btn btn-secondary"
              data-container="body"
              data-toggle="tooltip"
              data-placement="top"
              title="Please contact the help desk for further information">?</button>
          </li>
        </ul>
      </div>
    </div>
    <slot />

  </fieldset>
</template>

<script>
import { eventBus } from '../../app';

export default {
  props: {
    uLcos: {
      type: Array,
      default() {
        return [];
      },
    },
    uName: {
      type: String,
      default: 'Operative',
    },
    uMembership: {
      type: Boolean,
      default: false,
    },
    uProfile: {
      type: Object,
      default() {
        return [];
      },
    },
    /**
         * Specifify if user has red card
         */
    redCard: {
      type: Boolean,
      default: false,
    },
    uPicture: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dateTimeNow: Date.now(),
      checkedMembership: this.uMembership,
      verifyClass: '',
      verifyDisable: true,
      picture: false,
      expiredCert: 0,
    };
  },
  computed: {
    operativeAge() {
      const today = new Date();
      const birthDate = new Date(
        this.uProfile.personalInformation.date_of_birth,
      );
      let age = today.getFullYear() - birthDate.getFullYear();
      const m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    generalVerificationCheck() {
      return (
        this.isCitbValid()
                && this.checkedMembership
                && !this.redCard
                && this.picture
      );
    },
  },
  watch: {
    checkedMembership() {
      this.canBeVerified();
    },
  },
  mounted() {
    const componentEl = this;
    eventBus.$on('idphoto-change', (data) => {
      componentEl.picture = data.picture;
      componentEl.canBeVerified();
    });
    eventBus.$on('certs-updated', (data) => {
      this.checkFileExpiry(data.uploaded);
    });
  },
  created() {
    this.canBeVerified();
  },
  methods: {
    isCitbValid() {
      const reducedExpiry = this.uProfile.qualifications.expiry_date.split(
        ' ',
      );
      const newExpiry = reducedExpiry[0].split('-');
      const date = new Date();
      // Hours part from the timestamp
      const day = date.getDate();
      const monthIndex = date.getMonth() + 1;
      const year = date.getFullYear();

      let expiryDate;
      let newDate;

      if (this.uProfile.personalInformation.card_type === 'citb') {
        newDate = new Date(`${year}/${monthIndex}/01 00:00:00`);
        expiryDate = new Date(
                    `${newExpiry[0]}/${newExpiry[1]}/31 00:00:00`,
        );
      } else {
        newDate = new Date(`${year}/${monthIndex}/${day} 00:00:00`);
        expiryDate = new Date(
                    `${newExpiry[0]}/${newExpiry[1]}/${newExpiry[2]} 00:00:00`,
        );
        if (this.uProfile.personalInformation.card_type === 'cert') {
          expiryDate.setMonth(expiryDate.getMonth() + 3);
        }
      }

      return expiryDate > newDate;
    },
    canBeVerified() {
      const result = this.generalVerificationCheck;

      if (result === false) {
        this.verifyClass = 'button--faded';
        this.verifyDisable = true;
      } else {
        this.verifyClass = '';
        this.verifyDisable = false;
      }
    },
    checkFileExpiry(files) {
      this.expiredCert = 0;
      files.forEach((certs) => {
        certs.files.forEach((file) => {
          if (file.expiry_date === 'Expired') this.expiredCert++;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.verify-user {
    &__warnings {
        text-align: left;
        border-radius: 1rem;
        border: 1px solid red;
        background-color: white;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        &--header {
            padding: 1rem;
            background-color: red;
            border-top-left-radius: 1rem;
            border-top-right-radius: 1rem;
            color: white;
            font-weight: 600;
        }
        &--body {
            padding: 1rem;
        }
    }
}
</style>
