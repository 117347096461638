import moment from 'moment';

export default {
  name: 'VideoPlayer',
  props: {
    baseUrl: {
      type: String,
      default: 'https://player.vimeo.com/video/',
    },
    videoId: {
      type: String,
      default: '271839490',
    },
    videoAdded: {
      type: Object,
      default: moment(),
    },
    allow: {
      type: String,
      default: 'fullscreen',
    },
  },
  computed: {
    videoAddedFormatted() {
      return this.videoAdded.format('YYYY-MM-DD HH:mm:ss');
    },
  },
};
