import axios from 'axios';
import Vue from 'vue';
import cssVars from 'css-vars-ponyfill';
import CardCheckerList from '../components/cms/CardCheckerList.vue';

window.addEventListener('DOMContentLoaded', () => {
  const cardCheckerEl = '[data-vm="card-checker-management"]';
  if (document.querySelector(cardCheckerEl)) {
    new Vue({
      el: cardCheckerEl,
      components: {
        CardCheckerList,
      },
      mounted() {
        Vue.nextTick().then(() => {
          cssVars();
        });
      },
    });
  }
});
