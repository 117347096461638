export default class Form {
  /**
     * Construct a new form.
     */
  constructor() {
    this.bag = {};

    this.callbacks = {
      submitting: [],
      submitted: [],
      success: [],
      error: [],
    };
  }

  /**
     * Submit the form.
     *
     * @param  {String} target
     * @param  {String} method
     * @param  {Object} data
     * @param  {String} _method
     *
     * @return {Void}
     */
  submit(target, method, data, _method) {
    this.callbacks.submitting.forEach(callback => callback());

    window.axios[method.toLowerCase()](target, data).then((response) => {
      this.bag = {};

      this.callbacks.submitted.forEach(callback => callback());
      this.callbacks.success.forEach(callback => callback(response));
    }).catch((error) => {
      if (error.response && error.response.status === 422) {
        this.bag = error.response.data;
      } else {
        this.bag = {};
      }

      this.callbacks.submitted.forEach(callback => callback());
      this.callbacks.error.forEach(callback => callback(error));
    });
  }

  /**
     * Add a `submitting` callback.
     *
     * @param  {Function} callback
     *
     * @return {Void}
     */
  submitting(callback) {
    this.callbacks.submitting.push(callback);
  }

  /**
     * Add a `submitted` callback.
     *
     * @param  {Function} callback
     *
     * @return {Void}
     */
  submitted(callback) {
    this.callbacks.submitted.push(callback);
  }

  /**
     * Add a `success` callback.
     *
     * @param  {Function} callback
     *
     * @return {Void}
     */
  success(callback) {
    this.callbacks.success.push(callback);
  }

  /**
     * Add an `error` callback.
     *
     * @param  {Function} callback
     *
     * @return {Void}
     */
  error(callback) {
    this.callbacks.error.push(callback);
  }

  /**
     * Get a field's errors.
     *
     * @param  {String} field
     *
     * @return {Array|Object}
     */
  errors(field = null) {
    if (field === null) {
      return this.bag;
    }

    const errors = this.bag[field];

    if (errors === undefined || errors === null) {
      return [];
    }

    return errors;
  }
}
