import { Breadcrumb } from '@tribus/breadcrumbs';

export default {
  extends: Breadcrumb,
  name: 'MiPreBreadcrumbs',
  props: {
    linkClass: {
      type: String,
      default: 'Link',
    },
    url: {
      type: String,
      default: '#',
    },
    target: {
      type: String,
      default: '_self',
    },
  },
};
