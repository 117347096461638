import moment from 'moment';
import VideoPlayer from '../video-player/VideoPlayer.vue';

export default {
  name: 'VideoPlayerLoader',
  components: {
    VideoPlayer,
  },
  props: {
    video: {
      type: Object,
      default() {
        return {
          id: '',
          base: 'https://player.vimeo.com/video/',
          dateAdded: moment().format('YYYY-MM-DD'),
        };
      },
    },
  },
  data() {
    return {
      videoId: '',
    };
  },
  mounted() {
    if (this.video.id !== '') {
      this.getVideo();
    }
  },
  computed: {
    dateAddedFormatted() {
      if (this.video.dateAdded) {
        return moment(this.video.dateAdded).format('Do MMMM YYYY');
      }
      return moment().format('Do MMMM YYYY');
    },
    dateAdded() {
      if (this.video.dateAdded) {
        return moment(this.video.dateAdded);
      }
      return moment();
    },
  },
  methods: {
    getVideo() {
      if (this.video.id !== '') {
        this.videoId = this.video.id;
      }
    },
    replaceVideo() {
      this.videoId = '';
    },
  },
};
